import React, { Component } from 'react'
import Carousel from '@brainhubeu/react-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleLeft,
  faAngleRight,
} from '@fortawesome/pro-light-svg-icons';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class PromotionalProductsCarousel extends Component {

  constructor(props) {
    super(props);

    this.state = { width: 0 }
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth});
  }

  onCarouselItemClick = (id, productSKU, productCategory) => {
    this.props.history.push(`category/${id}/product-detail/${productSKU}/${productCategory}`)
  }

  getParentCategoryID = (productCategoryID) => {
    if (this.props.baselineLoad) {
      const id = this.props.baselineLoad[1].parentCategoryIds.filter((category) => {
        return category.parentCategoryID === productCategoryID
      })
      if (id[0]) {
        return id[0].parentCategoryID
      }

    }
  }

  render() {
    const { products, parentCategoryID } = this.props;
    const { width } = this.state;

    if (!products) return ('')

    return (
      <Carousel
        keepDirectionWhenDragging={false}
        className={'promotional-products'}
        arrowRight={width < 600 ? '' : <FontAwesomeIcon icon={faAngleRight} size='5x' />}
        arrowLeft={width < 600 ? '' : <FontAwesomeIcon icon={faAngleLeft} size='5x' />}
        stopAutoPlayOnHover={true}
        arrows={width < 500 ? false : true}
        autoPlay={3500}
        animationSpeed={1000}
        dots={true}
        slidesPerPage={1}
        addArrowClickHandler={width < 500 ? false : true}
        infinite={true}
        minDraggableOffset={122}
      >
        {
          products.map((item, index) => {
            let id = parentCategoryID;
            if (!id) {
              id = this.getParentCategoryID(item.parentCategoryID)
            }
            return (
              <div
                key={id + item.productSKU + item.parentCategoryID}
                onClick={() => this.onCarouselItemClick(id, item.productSKU, item.parentCategoryID)}
              >
                <img
                  alt="product"
                  className={'carousel-img'}
                  src={item.bannerImage}
                />
              </div>
            )
          })
        }
      </Carousel >
    )
  }
}

const mapStateToProps = (state) => {
  return {
    baselineLoad: state.productCategoryReducer.baselineLoad
  }
}

export default connect(mapStateToProps)(withRouter(PromotionalProductsCarousel));