
// product category
export const GET_FEATURED_PRODUCTS = 'GET_FEATURED_PRODUCTS'
export const GET_PROMOTIONAL_PRODUCTS = 'GET_PROMOTIONAL_PRODUCTS';
export const SET_PARENT_CATEGORYIDS = 'SET_PARENT_CATEGORYIDS';
export const GET_PRODUCTCATEGORY_ITEMS = 'GET_PRODUCTCATEGORY_ITEMS';
export const GET_CHILD_CATEGORY_ITEMS = 'GET_CHILD_CATEGORY_ITEMS';
export const GET_BASELINE_LOAD = 'GET_BASELINE_LOAD';

// product detail
export const GET_PRODUCT_DETAIL_BY_SKU = 'GET_PRODUCT_DETAIL_BY_SKU';
export const GET_RELATED_PRODUCTS = 'GET_RELATED_PRODUCTS';
export const GET_SPEAKER_AUDIO = 'GET_SPEAKER_AUDIO';

// product search
export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS';
export const GET_PRODUCT_REDIRECT_URL = 'GET_PRODUCT_REDIRECT_URL'; 

// user
export const SET_CURRENCY = 'SET_CURRENCY';
export const SELECTED_COUNTRY = 'SELECTED_COUNTRY';
export const SET_LANG_API_PARAM = 'SET_LANG_API_PARAM';
export const SET_CUSTOMER_AND_MARKET_ID_PARAM = 'SET_CUSTOMER_AND_MARKET_ID_PARAM'
export const LOGIN_USER = 'LOGIN_USER'
export const VERIFY_USER = 'VERIFY_USER';
export const ADD_ITEM_TO_CART = 'ADD_ITEM_TO_CART';
export const GET_USER_CART = 'GET_USER_CART';
export const SEND_PRODUCT_EMAIL = 'SEND_PRODUCT_EMAIL';
export const GET_CUSTOMER_ENROLL_WITH_PRODUCT_LINK = 'GET_CUSTOMER_ENROLL_WITH_PRODUCT_LINK';
export const GET_CART_GUID = 'GET_CART_GUID';
export const GET_CART_TOTALS = 'GET_CART_TOTALS';
export const DELETE_CART_ITEM = 'DELETE_CART_ITEM';
export const UPDATE_CART_ITEM = 'UPDATE_CART_ITEM';
export const NUMBER_CART_ITEMS = 'NUMBER_CART_ITEMS';
export const VERIFY_TOKEN = 'VERIFY_TOKEN';
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const GET_REGIONS = 'GET_REGIONS';
export const SET_USER_TOKEN = 'SET_USER_TOKEN';
export const VERIFY_GUEST_TOKEN = 'VERIFY_GUEST_TOKEN';
export const SET_COUNTRY_INDEX = 'SET_COUNTRY_INDEX';

export const API = "API";
export const API_START = "API_START";
export const API_END = "API_END";
export const ACCESS_DENIED = "ACCESS_DENIED";
export const API_ERROR = "API_ERROR";