export function findChildCategoryNode(id, currentNode) {
    var i,
        currentChild,
        result;

    if (id == currentNode.childCategoryID || id == currentNode.subCategoryID) {
        return currentNode;
    } else {

        // Use a for loop instead of forEach to avoid nested functions
        // Otherwise "return" will not work properly
        for (i = 0; currentNode.childCategories !== undefined && i < currentNode.childCategories.length; i += 1) {
            currentChild = currentNode.childCategories[i];

            // Search in the current child
            result = findChildCategoryNode(id, currentChild);

            // Return the result if the node has been found
            if (result !== false) {
                return result;
            }
        }

        for (i = 0; currentNode.subCategories !== undefined && i < currentNode.subCategories.length; i += 1) {
            currentChild = currentNode.subCategories[i];

            // Search in the current child
            result = findChildCategoryNode(id, currentChild);

            // Return the result if the node has been found
            if (result !== false) {
                return result;
            }
        }

        // The node has not been found and we have no more options
        return false;
    }
}