import React, { Component } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import {
  verifyToken,
  setLangApiParam,
  selectedCountry,
  setCustomerAndMarketIDParam,
  setCurrency,
  getUserDetails,
  getBaselineLoad,
  getFeaturedProducts,
  getPromotionalProducts,
  createCartGuid,
  verifyGuestLogin,
  getRegionsByCountry,
  setCountryIndex
} from '../store/actions';
import { deleteCookie, getCookie, setCookie } from '../utils/cookieUtil';
import qs from 'qs';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { faTrafficLightStop } from '@fortawesome/pro-light-svg-icons';

class PrivateRoute extends Component {
  state = {
    haveAccess: false,
    loaded: false,
    isGuest: false,
    showGuestLoginModal: false,
    showGuestLoginErrorMessage: null,
    marketIds: [],
    errorMessage: null,
    selectedCountryValue: null,
    regions: [],
    selectedRegionValue: null,
    guestToken: null,
    hideNav: false
  };

  componentDidUpdate = async (prevProps, prevState) => {
    let access_token = getCookie('access_token');
    let isGuestCookie = getCookie('is_guest');
    const guest = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).guest;

    const bHideNav = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).hidenav;
    if (prevState.hideNav !== this.state.hideNav) {
      if (!!bHideNav && (bHideNav === "1" || bHideNav === "true")) {
        this.setState({ hideNav: true });
      } else if (!!bHideNav && (bHideNav === "0" || bHideNav === "false")) {
        this.setState({ hideNav: false });
      }
    }
    console.log('this.state.hideNav: ', this.state.hideNav);
    //guest token was updated 
    if ((prevState.guestToken !== this.state.guestToken) && guest) {
      if (this.isGuestLogin()) {
        this.setState({ isGuest: true });
        return;
      }
    }

    if (isGuestCookie) {
      if (prevProps.marketAndCustomerLevelID !== this.props.marketAndCustomerLevelID ||
        prevProps.langApi !== this.props.langApi) {

        let customerLevelID = null;
        let marketID = null;
        let selected_lang = getCookie('selected_lang');
        let country = JSON.parse(getCookie('guest_country'));

        if (!country.customerLevelID || !country.marketID) {
          deleteCookie("access_token");
          deleteCookie("cart_guid");
          deleteCookie("selected_lang");
          deleteCookie("guest_country");
          deleteCookie("guest_region");
          deleteCookie("is_guest");
          deleteCookie("is_quick_buy");
          console.log('this is where I am kicked out');
          this.props.history.push('/login');
        }

        customerLevelID = country.customerLevelID;
        marketID = country.marketID;

        this.props.getFeaturedProducts(access_token, selected_lang, customerLevelID, marketID);
        this.props.getPromotionalProducts(access_token, selected_lang, customerLevelID, marketID);
      }
      return;
    }

    this.props.verifyToken(access_token, ((response) => {
      if (this.props.path !== prevProps.path && this.props.userDetails[0].allowToBuy === false) {
        var pathArray = window.location.pathname.split('/');
        if (pathArray[2] !== "790" && pathArray[1] !== 'redirect') {
          this.props.history.push('/category/790');
        }
      }
    }).bind(this), ((error) => {
      deleteCookie("access_token");
      deleteCookie("cart_guid");
      deleteCookie("selected_lang");
      this.props.history.push('/login');
    }).bind(this));

  };

  componentDidMount = async () => {
    if (this.isGuestLogin()) {
      this.setState({ isGuest: true });
      return;
    }

    const query = new URLSearchParams(this.props.location.search);
    const is_desktop = query.get('desktop');
    if (is_desktop) {
      setCookie("is_desktop", is_desktop, 30);
    }
    const bHideNav = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).hidenav;
    if (!!bHideNav && (bHideNav === "1" || bHideNav === "true")) {
      this.setState({ hideNav: true });
    }

    const token = query.get('token');

    let access_token = getCookie('access_token');
    if (access_token == null) {
      access_token = token;
    }
    this.access_token = access_token;

    if (access_token) {
      this.props.verifyToken(access_token, ((response) => {
        this.props.createCartGuid(access_token, ((response) => {
          setCookie("cart_guid", response.cartID, 30);
          this.props.getUserDetails(access_token, ((response) => {
            this.setGlobalVariables(response);
          }).bind(this));
        }).bind(this));
      }).bind(this), ((error) => {
        deleteCookie("access_token");
        deleteCookie("cart_guid");
        deleteCookie("selected_lang");
        this.props.history.push('/login');
      }).bind(this));
    } else {
      deleteCookie("access_token");
      deleteCookie("cart_guid");
      deleteCookie("selected_lang");
      this.props.history.push('/login');
    }
  };

  clearErrorMessage = () => {
    this.setState({ errorMessage: null });
  };

  clearShowGuestLoginErrorMessage = () => {
    this.setState({ showGuestLoginErrorMessage: null });
  };

  toggleShowGuestLoginModal = () => {
    this.setState({ showGuestLoginModal: !this.state.showGuestLoginModal });
  };

  setLanguage = (value) => {
    this.props.setLangApiParam(value);
    setCookie("selected_lang", value, 30);
  };

  onLanguageDropdownChange = (e) => {
    if (e && e.value) {
      this.setLanguage(e.value);
    }
  };

  setCountry = (value, shouldCompleteGuestLogin) => {
    let access_token = getCookie('access_token');
    const selectedLang = getCookie('selected_lang');
    console.log('value: ', value);
    this.props.getBaselineLoad(access_token, value.countryValue);
    this.setState({ selectedCountryValue: value.countryValue });
    this.props.getFeaturedProducts(access_token, selectedLang, value.customerLevelID, value.marketID);
    this.props.getPromotionalProducts(access_token, selectedLang, value.customerLevelID, value.marketID);
    this.props.selectedCountry(value);
    setCookie("guest_country", JSON.stringify(value), 30);
    this.props.setCustomerAndMarketIDParam({
      customerLevelID: value.customerLevelID,
      marketID: value.marketID
    });
    this.props.setCurrency(value.currency);
    this.props.getRegionsByCountry(value.countryValue, ((response) => {
      if (response && response[0] && response[0].regions) {
        let regions = response[0].regions;
        let newRegions = [];
        regions.forEach((obj, i) => {
          newRegions.push({ label: obj.name, value: obj });
        });
        this.setState({ regions: newRegions });
      }
    }).bind(this), ((error) => {
      this.setState({ errorMessage: 'Unable to fetch available regions!' });
    }).bind(this));

    if (shouldCompleteGuestLogin) {
      this.setState({ haveAccess: true, loaded: true });
    }

  };

  onCountryDropdownChange = (e) => {
    if (e && e.value) {
      this.setCountry(e.value, false);
    }
  };

  setGuestRegion = (value) => {
    setCookie("guest_region", JSON.stringify(value), 30);
    this.setState({ selectedRegionValue: value.shortCode });
  };

  onRegionDropdownChange = (e) => {
    if (e && e.value) {
      this.setGuestRegion(e.value);
    }
  };

  guestAlreadyLoggedIn = (country, region = "") => {
    if (country) {
      this.setCountry(country, true);
    }
    if (region) {
      this.setGuestRegion(region);
    }
  };

  isGuestLogin = () => {
    const guest = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).guest;
    const isGuestCookie = getCookie('is_guest');

    if (!isGuestCookie && !guest) {
      return false;
    } else {
      //set guest token in state to track updates 
      this.setState({ guestToken: guest });
    }

    // check if already setup cookies for guest
    let access_token = getCookie('access_token');
    const country = JSON.parse(getCookie('guest_country'));
    const selectedLang = getCookie('selected_lang');
    const cart_guid = getCookie('cart_guid');
    let didGuestLoginAlready = false;
    if (!isGuestCookie || !access_token || !country || !cart_guid) {
      if (!guest) {
        return false;
      }
      deleteCookie("access_token");
      deleteCookie("cart_guid");
      deleteCookie("selected_lang");
      deleteCookie("guest_country");
      deleteCookie("guest_region");
      deleteCookie("is_guest");
      deleteCookie("is_quick_buy");
    } else {
      didGuestLoginAlready = true;
    }

    //set isGuestCookie 
    setCookie("is_guest", true, cookieExp);

    const cookieExp = 1;
    this.props.verifyGuestLogin(didGuestLoginAlready ? access_token : guest, ((response) => {
      const payload = response.payload;
      if (payload) {
        if (!didGuestLoginAlready) {
          this.setState({ guestToken: guest });
          setCookie("access_token", guest, cookieExp);
          this.setLanguage(payload.lang === "" ? "en" : payload.lang);
          this.props.createCartGuid(guest, ((response) => {
            setCookie("cart_guid", response.cartID, 1);
          }));
        } else {
          this.setLanguage(selectedLang);
        }

        setCookie("is_quick_buy", payload.isQuickBuy, cookieExp);
        setCookie("is_guest", payload.isGuest, cookieExp);

        this.props.getBaselineLoad(didGuestLoginAlready ? access_token : guest, 'US', ((response) => {
          if (response && response[0] && response[0].marketIds) {
            let marketIds = response[0].marketIds;
            let newMarketIds = [];
            marketIds.forEach((obj, i) => {
              newMarketIds.push({ label: obj.countryName, value: obj });
            });
            this.setState({ marketIds: newMarketIds });

            let guestCountry = payload.guestCountry;
            let userCountry = marketIds.filter(element => (element.countryValue === guestCountry));

            if (!didGuestLoginAlready) {
              let selectedCountryIndex = marketIds.findIndex(element => (element.countryValue === guestCountry));
              this.props.setCountryIndex(selectedCountryIndex);
              this.setCountry(userCountry[0], true);
            } else {
              const country = JSON.parse(getCookie('guest_country'));
              let selectedCountryIndex = marketIds.findIndex(element => (element.countryValue === country.countryValue));
              this.props.setCountryIndex(selectedCountryIndex);
              this.setCountry(country, true);
            }

          } else {
            this.setState({ errorMessage: 'Unable to fetch available countries!' });
          }
        }), ((error) => {
          this.setState({ errorMessage: 'Unable to fetch available countries!' });
        }));

      }
    }), ((error) => {
      this.setState({ errorMessage: 'Sorry, your link has expired. Contact the sender and they can easily forward you a new one.' });
    }));

    return true;
  };

  setGlobalVariables = (userData) => {
    this.props.getBaselineLoad(this.access_token, '', ((response) => {
      let countryParams;
      response[0].marketIds.forEach((country, i) => {
        if (country.countryValue === userData[0].Country) {
          this.props.selectedCountry(country);
          countryParams = country;
        }
      });
      this.props.setCustomerAndMarketIDParam({
        customerLevelID: userData[0].CustomerLevelID,
        marketID: userData[0].MarketID
      });
      let selected_lang = getCookie("selected_lang");
      if (selected_lang) {
        this.props.setLangApiParam(selected_lang);
      } else this.props.setLangApiParam(userData[0].Language);
      this.props.setCurrency(countryParams.currency);
      this.props.getFeaturedProducts(this.access_token, userData[0].Language);
      this.props.getPromotionalProducts(this.access_token, userData[0].Language);

      this.setState({ haveAccess: true, loaded: true });

      if (userData[0].allowToBuy === false) {
        var pathArray = window.location.pathname.split('/');
        if (pathArray[2] !== "790" && pathArray[1] !== 'redirect') {
          this.props.history.push('/category/790');
        }
      }
    }).bind(this));
  };

  guestLogin = () => {
    this.toggleShowGuestLoginModal();
    this.setState({ haveAccess: true, loaded: true });
  };

  GuestLoginModal = () => {
    const {
      showGuestLoginModal,
      showGuestLoginErrorMessage
    } = this.state;

    if (!showGuestLoginModal) {
      return null;
    }

    const langOptions = [
      { value: 'en', label: 'English' },
      { value: 'es', label: 'Spanish' },
      { value: 'fr', label: 'French' }
    ];

    return (
      <div className="modal modal-hover modal-dialog modal-dialog-centered modal-product-image-cart" role="document">
        <div id="shareProductView" className="modal-content modal-product-image__wrapper cart-img">
          <div>
            <div className="modal-product-image-cart__header">
              <h1 className="guestLoginHeader">Guest Login</h1>
            </div>

            <div className="modal-share-product-alert" hidden={showGuestLoginErrorMessage === null}>
              <span className="modal-share-product-closebtn" onClick={() => this.clearShowGuestLoginErrorMessage()}>&times;</span>
              <strong>Error</strong> {showGuestLoginErrorMessage}
            </div>

            <div className="modal-share-product__fieldsGuestLogin">
              <div className="modal-share-product__inputgroup">
                <p>Language:</p>
                <Dropdown
                  className="dropdownGuestLogin"
                  options={langOptions}
                  onChange={this.onLanguageDropdownChange}
                  value={'en'} />
              </div>
              <div className="modal-share-product__inputgroup">
                <p>Country:</p>
                <Dropdown
                  className="dropdownGuestLogin"
                  options={this.state.marketIds}
                  onChange={this.onCountryDropdownChange}
                  value={'Pick a country'} />
              </div>
              <div className="modal-share-product__inputgroup">
                <p>Region:</p>
                <Dropdown
                  className="dropdownGuestLogin"
                  options={this.state.regions}
                  onChange={this.onRegionDropdownChange}
                  value={'Pick a region'}
                  disabled={this.state.selectedCountryValue == null} />
              </div>
            </div>

            <div className="modal-share-product__buttons">
              <button onClick={() => this.guestLogin()} className="btn btn-primary"
                disabled={this.state.selectedRegionValue == null} >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  ErrorMessageModal = ({ message, onClickHandler }) => {
    return (
      <div className="modal modal-hover modal-dialog modal-dialog-centered modal-product-image" role="document">
        <div className="modal-content modal-product-image__wrapper cart-img">
          <div className="modal-product-image__header">
            <h1>Error</h1>
          </div>
          <div className="product-description__product-pv">
            <p>{(message === null || message === undefined) ? '' : message}</p>
          </div>
          <div className="modal-product-image__buttons">
            <button onClick={() => onClickHandler()} className="btn btn-primary">
              OK
            </button>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const access_token = getCookie('access_token');
    const isQuickBuy = getCookie('is_quick_buy');
    const { component: Component, ...rest } = this.props;
    const { loaded, haveAccess, showGuestLoginModal, errorMessage, isGuest, hideNav } = this.state;
    if (errorMessage) {
      return <this.ErrorMessageModal message={this.state.errorMessage} onClickHandler={this.clearErrorMessage} />;
    }
    if (!loaded) {
      if (showGuestLoginModal) {
        return <this.GuestLoginModal />;
      }
      return null;
    }
    return (
      <Route
        {...rest}
        render={props => {
          return haveAccess && access_token !== null && (!isQuickBuy || this.props.isQuickBuyAllowed) ? (
            <Component {...props} accessToken={access_token} isGuest={isGuest} isQuickBuy={isQuickBuy} hideNav={hideNav} />
          ) : (
            <Redirect
              to={{
                pathname: '/login',
              }}
            />
          );
        }}
      />
    );
  }
}

const mapStateToUser = (state) => {
  return {
    userDetails: state.userReducer.userDetails,
    guestCountry: state.userReducer.guestCountry,
    region: state.userReducer.region,
    marketAndCustomerLevelID: state.userReducer.marketAndCustomerIdParam,
  };
};

export default connect(mapStateToUser, {
  verifyToken,
  setLangApiParam,
  selectedCountry,
  setCustomerAndMarketIDParam,
  setCurrency,
  getUserDetails,
  getBaselineLoad,
  getFeaturedProducts,
  getPromotionalProducts,
  createCartGuid,
  verifyGuestLogin,
  getRegionsByCountry,
  setCountryIndex
})(withRouter(PrivateRoute));