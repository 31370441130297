import * as actionTypes from '../actions/actionTypes';

export const productReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_FEATURED_PRODUCTS:
      return {
        ...state,
        featuredProducts: action.payload
      }

    case actionTypes.GET_PROMOTIONAL_PRODUCTS:
      return {
        ...state,
        promotionalProducts: action.payload
      }

    case actionTypes.SEARCH_PRODUCTS:
      return {
        ...state,
        searchProductList: action.payload
      }

    case actionTypes.GET_PRODUCT_REDIRECT_URL:
      return {
        ...state,
        redirectURL: action.payload.redirectURL
      }

    default:
      return state;
  }
}

export default productReducer;