import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import SpeakerModal from './SpeakerModal';
import { withTranslation } from 'react-i18next';

class Speaker extends Component {
  state = {
    audioList: [],
    currentSongURL: undefined,
    currentIdx: null
  }

  pauseTrack = (idx) => {
    if (this.state.currentSongURL) {
      this.setState({ currentIdx: idx })
      this.state.currentSongURL.pause();
    }
  }

  playTrackAudio = (audioURL, idx) => {
    this.setState({ currentIdx: idx })

    let audio = new Audio(audioURL)

    if (!this.state.currentSongURL) {
      this.setState({ currentSongURL: audio })
      audio.play();
      return
    }

    if (this.state.currentSongURL.src !== audioURL) {
      this.state.currentSongURL.pause();
      this.setState({ currentSongURL: audio })
      audio.play();
      return;
    }

    audio.play();
  }

  onShowAllClick = (speaker) => {
    this.props.history.push({
      pathname: '/search',
      speaker: speaker
    })
  }

  renderMediaListItems = () => {
    const { speakerAudio } = this.props
    if (speakerAudio) {
      return speakerAudio.map((item, index) => {
        return <SpeakerModal
          onAdditionalProductHandler={this.props.onAdditionalProductHandler}
          currentIdx={this.state.currentIdx}
          idx={index}
          key={index}
          pauseTrack={(index) => this.pauseTrack(index)}
          playTrackAudio={(songURL, index) => this.playTrackAudio(songURL, index)}
          audioItem={item} />
      })
    }
  }
 
  render() {
    const { t } = this.props;
    return (
      <div className="modal fade"
        id="speakerModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-speaker" role="document">
          <div id="els" className="modal-content modal-speaker__wrapper">
            <div className="modal-speaker__header">
              <div className="speaker-wrapper">
                <h1>{t('audiomodal.audiosby')} {this.props.speakerName}</h1>
              </div>
              <i data-dismiss="modal" className="fas fa-times-circle"></i>
            </div>
              <p className="modal-speaker__showall" data-dismiss="modal" onClick={() => this.onShowAllClick(this.props.speakerName)}>
                {t('audiomodal.showall')}
              </p>
            {this.renderMediaListItems()}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(withTranslation()(Speaker));
