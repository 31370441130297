import { combineReducers } from 'redux';
import productSearchReducer from './productSearchReducers';
import userReducer from './userReducers';
import productCategoryReducer from './productCategoryReducers';
import productDetailReducer from './productDetailReducer';

export default combineReducers({
  productCategoryReducer,
  productSearchReducer,
  userReducer,
  productDetailReducer
}); 
