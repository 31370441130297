import options from "../../config";
import * as actionTypes from './actionTypes';
import { apiAction } from './api';

export const getBaselineLoad = (access_token, guestCountry, onSuccess = (res) => {}, onFailure = (e) => {}, onFinally = () => {}) => apiAction({
  url: `${options.APIURL}/api/shoppingcart/setup/${guestCountry}`,
  onSuccess: (response) => {
    onSuccess(response);
    return {
      type: actionTypes.GET_BASELINE_LOAD,
      payload: response
    };
  },
  onFailure: (error) => {
    if (onFailure) {
      onFailure(error);
    }
    console.log("getBaselineLoad api error");
  },
  onFinally: () => {
    onFinally();
  },
  accessToken: access_token
});

export const getPromotionalProducts = (access_token, lang, customerLevelID, marketID, onSuccess = (res) => {}, onFailure = (e) => {}, onFinally = () => {}) => apiAction({
  url: customerLevelID ? `${options.APIURL}/api/shoppingcart/parentcategory/792/${lang}?guestCustomerLevelID=${customerLevelID}&guestMarketID=${marketID}` : `${options.APIURL}/api/shoppingcart/parentcategory/792/${lang}`,
  onSuccess: (response) => {
    onSuccess(response);
    return {
      type: actionTypes.GET_PROMOTIONAL_PRODUCTS,
      payload: response
    };
  },
  onFailure: (error) => {
    if (onFailure) {
      onFailure(error);
    }
    console.log("getPromotionalProducts api error");
  },
  onFinally: () => {
    onFinally();
  },
  accessToken: access_token
});

export const getFeaturedProducts = (access_token, lang, customerLevelID, marketID, onSuccess = (res) => {}, onFailure = (e) => {}, onFinally = () => {}) => apiAction({
  url: customerLevelID ? `${options.APIURL}/api/shoppingcart/parentcategory/793/${lang}?guestCustomerLevelID=${customerLevelID}&guestMarketID=${marketID}` : `${options.APIURL}/api/shoppingcart/parentcategory/793/${lang}`,
  onSuccess: (response) => {
    onSuccess(response);
    return {
      type: actionTypes.GET_FEATURED_PRODUCTS,
      payload: response
    };
  },
  onFailure: (error) => {
    if (onFailure) {
      onFailure(error);
    }
    console.log("getFeaturedProducts api error");
  },
  onFinally: () => {
    onFinally();
  },
  accessToken: access_token
});

export const getProductsByCategoryId = (categoryId, access_token, lang, customerLevelID, marketID, onSuccess = (res) => {}, onFailure = (e) => {}, onFinally = () => {}) => apiAction({
  url: customerLevelID ? `${options.APIURL}/api/shoppingcart/parentcategory/${categoryId}/${lang}?guestCustomerLevelID=${customerLevelID}&guestMarketID=${marketID}` : `${options.APIURL}/api/shoppingcart/parentcategory/${categoryId}/${lang}`,
  onSuccess: (response) => {
    onSuccess(response)
    return {
      type: actionTypes.GET_PRODUCTCATEGORY_ITEMS,
      payload: response
    };
  },
  onFailure: (error) => {
    if (onFailure) {
      onFailure(error);
    }
    console.log("getProductsByCategoryId api error");
  },
  onFinally: () => {
    onFinally();
  },
  label: actionTypes.GET_PRODUCTCATEGORY_ITEMS,
  accessToken: access_token
});

// SubCategories List with pagin..
// THIS API DOES NOT CHANGES PRODUCT BY LANGUAGE --

export const getProductsByChildCategoryId = (childCategoryId, access_token, lang, pageIndex, guestCustomerLevelID, guestMarketID, onSuccess = (res) => {}, onFailure = (e) => {}, onFinally = () => {}) => apiAction({
  url: guestCustomerLevelID ? `${options.APIURL}/api/shoppingcart/category/${childCategoryId}/filter?pageindex=${pageIndex}&pagesize=30&showAll=0&lang=${lang}&guestCustomerLevelID=${guestCustomerLevelID}&guestMarketID=${guestMarketID}` : `${options.APIURL}/api/shoppingcart/category/${childCategoryId}/filter?pageindex=${pageIndex}&pagesize=30&showAll=0&lang=${lang}`,
  onSuccess: (response) => {
    onSuccess(response);
    return {
      type: actionTypes.GET_CHILD_CATEGORY_ITEMS,
      payload: response
    };
  },
  onFailure: (error) => {
    if (onFailure) {
      onFailure(error);
    }
    console.log("getProductsByChildCategoryId api error");
  },
  onFinally: () => {
    onFinally();
  },
  label: actionTypes.GET_CHILD_CATEGORY_ITEMS,
  accessToken: access_token
});

