import * as actionTypes from '../actions/actionTypes';

export const productReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_PRODUCT_DETAIL_BY_SKU:
      return {
        ...state,
        productDetails: action.payload
      }
    case actionTypes.GET_RELATED_PRODUCTS:
      return {
        ...state,
        relatedProducts: action.payload
      }
    case actionTypes.GET_SPEAKER_AUDIO:
      return {
        ...state,
        speakerAudio: action.payload
      }
    default:
      return state;
  }
}

export default productReducer;