import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import ProductCategory from './components/ProductCategory/ProductCategory';
import SubCategoryList from './containers/SubCategoryList/SubCategoryList';
import ProductRedirect from './components/ProductRedirect';
import './i18n';
import './static/sass/index.scss';
import HomeComponent from './components/Home/Home';
import ProductDetail from './containers/ProductDetail/ProductDetail';
import Search from './containers/Search/Search';
import Cart from './containers/Cart/Cart';
import AsyncPrivateRoute from './containers/AsyncPrivateRoute';
import Login from './containers/Login';
import NoMatch from './components/NoMatch';

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <AsyncPrivateRoute path={'/'} exact component={HomeComponent} />
        <AsyncPrivateRoute path={`/:categoryId/product-detail/:productSKU/:productCategoryID`}
          component={ProductDetail} isQuickBuyAllowed={true}
        />
        <AsyncPrivateRoute
          component={ProductRedirect}
          path={`/redirect/:productID`}
          isQuickBuyAllowed={true}
        />
        <AsyncPrivateRoute
          path={`/category/:categoryId/product-detail/:productSKU/:productCategoryID`}
          component={ProductDetail}
          isQuickBuyAllowed={true}
        />
        <AsyncPrivateRoute
          component={SubCategoryList}
          isQuickBuyAllowed={true}
          path={`/category/:categoryId/sub-category/:subCategoryID`}
        />
        <AsyncPrivateRoute
          component={ProductCategory}
          isQuickBuyAllowed={true}
          path={`/category/:categoryId`}
        />
        <AsyncPrivateRoute path={`/search`} component={Search} />
        <AsyncPrivateRoute path={`/cart`} component={Cart} isQuickBuyAllowed={true} />
        <Route path="/login" component={Login} isQuickBuyAllowed={true} />
        <Route path={"/checkout"} />
        <Route component={NoMatch} isQuickBuyAllowed={true} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
