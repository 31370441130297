import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'
import { loginUser, createCartGuid, getUserDetails } from '../../../store/actions';
import { getCookie, setCookie, deleteCookie } from '../../../utils/cookieUtil';

class SignInModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      errorMessage: ''
    }
  }

  /* Set input vales to our state */
  onInputChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    this.setState({
      [name]: value,
      errorMessage: ''
    })
  }

  handleLogin = (e) => {
    e.preventDefault();

    this.props.loginUser(this.state, ((response) => {

      //set cookieExp based on remember_me check-box 
      let cookieExp = 1; 
      if (this.refs.remember_me.checked) {
        cookieExp = 30; 
      }

      deleteCookie("selected_lang");
      deleteCookie("guest_country");
      deleteCookie("guest_region");
      deleteCookie("is_guest");
      deleteCookie("is_quick_buy");

      if (response.access_token) {
        setCookie("access_token", response.access_token, cookieExp); 
        this.props.getUserDetails(response.access_token)
      }
      const cart_guid = getCookie('cart_guid');
      if (!cart_guid && response.access_token) {
        this.props.createCartGuid(response.access_token, (response) => {
          setCookie("cart_guid", response.cartID, cookieExp); 
        });
      }
    }).bind(this), ((error) => {
      if (error && error.data && error.data.message) {
        this.setState({ errorMessage: error.data.message })
      } else {
        this.setState({ errorMessage: 'Could not login!' })
      }
    }).bind(this));
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <form onSubmit={this.handleLogin}>
          <div className="modal-dialog modal-dialog-centered modal-signIn">
            <div className="modal-content modal-signIn__wrapper">
              <div className="modal-signIn__top-content">
                <h1 className="modal-signIn__title">{t('signinmodal.signin')}</h1>
                {
                  this.state.errorMessage.length > 1 ? (
                    <div className="modal-signIn__error">
                      <p>{this.state.errorMessage}</p>
                    </div>
                  ) : (
                      ''
                    )
                }
                <div className="modal-signIn__input">
                  <input
                    onChange={this.onInputChange}
                    value={this.state.username}
                    name="username"
                    type="text"
                    placeholder={t('signinmodal.username')}
                  />
                </div>
                <div className="modal-signIn__input">
                  <input
                    onChange={this.onInputChange}
                    value={this.state.password}
                    name="password"
                    type="password"
                    placeholder={t('signinmodal.password')}
                  />
                </div>
                <div className="modal-signIn__signIn-btn">
                  <button type='submit'>{t('signinmodal.signin')}</button>
                </div>
                <div className="modal-signIn__help">
                  <input type="checkbox" ref="remember_me" />
                  <p>{t('signinmodal.rememberme')}</p>
                  <p>{t('signinmodal.needhelp')}</p>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default connect(null, {
  loginUser, 
  createCartGuid,
  getUserDetails
})(withTranslation()(withRouter(SignInModal)));