import React from 'react'
import {Link} from 'react-router-dom'
import Footer from './Footer'

export default function NoMatch() {
  return (
    <div>
      <div>
        <h1>Opppssss... Sorry, no page exists here.</h1>
        <p>Please click the image below to be redirected</p>
        <Link to={'/'}>
            <img
            className="footer__logo_"
            src="https://main.secure.footprint.net/public/icon/life.png"
            alt=""
            />
        </Link>
      </div>
      <Footer />
    </div>
  )
}
