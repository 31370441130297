import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getProductRedirectURL } from '../store/actions';
import Container from 'react-bootstrap/Container';
import Footer from '../components/Footer';

class ProductRedirect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageIsRedirecting: true
        }
    }

    async componentDidMount() {
        const productCategoryID = parseInt(this.props.match.params.productID, 10);
        this.props.getProductRedirectURL(this.props.accessToken, productCategoryID, ((response) => {
            if(response != null) {
                this.setState({ pageIsRedirecting: false });
                this.props.history.push(response.redirectURL);
            } else {
                this.props.history.push('/');
            }
        }).bind(this), ((error) => {
            this.props.history.push('/');
        }).bind(this));
    }

    render() {
        return (
            <>
                {
                    this.state.pageIsRedirecting ? (
                        <Container className="search-main-content">
                            <div>
                                <h1 className="message-loading">Redirecting...<div className="lds-ring"><div></div><div></div><div></div><div></div></div> </h1>
                            </div>
                        </Container>
                    ) : ('')
                }
                <Footer />
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        redirectURL: state.productSearchReducer.redirectURL
    }
}

export default connect(mapStateToProps,{
    getProductRedirectURL
})(ProductRedirect);