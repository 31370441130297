import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router";
import { Redirect } from 'react-router-dom';
import { getCookie, deleteCookie } from '../../utils/cookieUtil';
import ProductList from '../../containers/ProductList/ProductList';
import CustomCarousel from '../../containers/Carousel/CustomCarousel';
import Container from 'react-bootstrap/Container';
import { getProductsByCategoryId, getUserDetails } from '../../store/actions';
import Header from '../Header';
import Footer from '../Footer';
import Row from 'react-bootstrap/Row';
import LoadingIndicator from '../LoadingIndicator';

class ProductCategory extends Component {

  constructor(props) {
    super(props);

    this.state = {
      categoryItems: [],
      parentCategory: '',
      childCategories: [],
      showFooter: false
    };
  }

  componentDidMount = async () => {
    if (this.props.baselineLoad) {
      this.setUpProductCategoryItems();
    }
    if (!getCookie('is_guest')) {
      this.props.getUserDetails(this.props.accessToken);
    }
  };

  filterCategoryItemsById = (childCategoryID) => {
    if (this.state.categoryItems && this.state.categoryItems.length > 0) {
      const items = this.state.categoryItems.filter((item) => {
        return item.productCategoryID === childCategoryID;
      });
      return items;
    }
  };

  setUpProductCategoryItems = () => {
    const categoryId = parseInt(this.props.match.params.categoryId, 10);
    const parentCategory = this.props.baselineLoad[1].parentCategoryIds.filter((category) => {
      return category.parentCategoryID === categoryId;
    });
    this.setState({ parentCategory: parentCategory[0].parentCategoryName });

    let customerLevelID = null;
    let marketID = null;

    if (this.props.isGuest) {
      const country = JSON.parse(getCookie('guest_country'));

      if (!country.customerLevelID || !country.marketID) {
        deleteCookie("access_token");
        deleteCookie("cart_guid");
        deleteCookie("selected_lang");
        deleteCookie("guest_country");
        deleteCookie("guest_region");
        deleteCookie("is_guest");
        deleteCookie("is_quick_buy");
        this.props.history.push('/login');
      }

      customerLevelID = country.customerLevelID;
      marketID = country.marketID;
    }

    this.props.getProductsByCategoryId(categoryId, this.props.accessToken, this.props.langApi, customerLevelID, marketID, ((response) => {
      this.setState({ categoryItems: response, showFooter: true });
      if (parentCategory[0].childCategories !== null) {
        this.setState({ childCategories: parentCategory[0].childCategories });
      }
    }).bind(this));
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.baselineLoad !== this.props.baselineLoad) {
      this.setUpProductCategoryItems();
    }
    if (prevProps.marketAndCustomerLevelID !== this.props.marketAndCustomerLevelID ||
      prevProps.langApi !== this.props.langApi) {
      this.setUpProductCategoryItems();
    }
    if (JSON.stringify(prevProps.productDetails) !== JSON.stringify(this.props.productDetails)) {
      this.setUpProductCategoryItems();
    }
  };

  seeAllSubCategoryItems = (childCategoryId) => {
    this.props.history.push({
      pathname: `${this.props.match.url}/sub-category/${childCategoryId}`,
    });
  };

  showHeader = () => {
    const { hideNav } = this.props;
    if (!!hideNav)
      return false;
    else
      return true;
  };

  render() {
    const { t, userDetails, allParentCategoryIds } = this.props;
    const { categoryItems, childCategories, parentCategory, showFooter } = this.state;

    let categories = [];
    if (categoryItems) {
      categories = childCategories.filter((childCategory) => {
        const id = categoryItems.filter((item) => {
          return item.productCategoryID === childCategory.childCategoryID;
        });
        if (!id.length < 1) {
          return childCategory;
        }
        return '';
      });
    }
    let isGuest = getCookie('is_guest');

    return (
      <>
        {
          (isGuest || userDetails[0].IsMember === '0') && ((parentCategory === "salesaid") || (parentCategory === "lifetraining")) ? (
            <Redirect
              to={{
                pathname: '/',
              }}
            />
          ) : (
            <>
              {
                this.props.isLoadingProductCategoryItems ?
                  (<LoadingIndicator />) :
                  (<div>
                    {
                      this.showHeader()
                        ? (<Header parentCategory={parentCategory} allParentCategoryIds={allParentCategoryIds} isGuest={this.props.isGuest} />)
                        : (<div className="quick-buy-spacer"></div>)
                    }
                    <Container className="category-view">
                      {
                        categories.length > 1 && parentCategory !== 'subscription' ? (
                          categories.map((childCategory, i) => {
                            return (
                              <div key={i} className="category-view__container">
                                <div className="category-view__title">
                                  <h1 className="category-view__title">{t(`subcategories.${childCategory.childCategoryName}`)}</h1>
                                  <p
                                    className="category-view__seeAll"
                                    onClick={() => this.seeAllSubCategoryItems(childCategory.childCategoryID)}>
                                    {t('subcategories.seeall')}</p>
                                </div>
                                <CustomCarousel
                                  parentCategoryID={this.props.match.params.categoryId}
                                  products={this.filterCategoryItemsById(childCategory.childCategoryID)}
                                />
                              </div>
                            );
                          })
                        ) : (
                          <div className="category-view__container">
                            <Row>
                              <ProductList
                                category={'category-view-img'}
                                products={categoryItems}
                              />
                            </Row>
                          </div>
                        )
                      }
                    </Container>
                  </div>)
              }
            </>
          )
        }
        {showFooter && <Footer />}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    productDetails: state.productCategoryReducer.productDetails,
    parentCategoryIds: state.productCategoryReducer.parentCategoryIds,
    marketAndCustomerLevelID: state.userReducer.marketAndCustomerIdParam,
    langApi: state.userReducer.langApiParam,
    baselineLoad: state.productCategoryReducer.baselineLoad,
    userDetails: state.userReducer.userDetails,
    isLoadingProductCategoryItems: state.productCategoryReducer.isLoadingProductCategoryItems
  };
};

export default connect(mapStateToProps, {
  getProductsByCategoryId,
  getUserDetails
})(withRouter(withTranslation()(ProductCategory)));