import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import BreadCrumb from './BreadCrumb/BreadCrumb';
import Navbar from './Navbar/Navbar';

class Header extends Component {
  render() {
    const { allParentCategoryIds, location: { pathname }, isGuest, disableCountryDDL } = this.props;
    return (
      <header className="header">
        <Navbar isGuest={isGuest} disableCountryDDL={disableCountryDDL}  />
        {pathname === '/' || pathname === '/search' || pathname.includes('/cart') ? '' : 
          <BreadCrumb 
            parentCategoryName={this.props.parentCategory}
            childCategory={this.props.childCategory}
            allParentCategoryIds={allParentCategoryIds} 
          />}
      </header>
    )
  }
}

export default withRouter(Header);