import * as actionTypes from './actionTypes';
import options from '../../config';
import { apiAction } from './api';

export const searchProducts = (searchItem, access_token, lang, postBody, onSuccess = (res) => {}, onFailure = (e) => {}, onFinally = () => {}) => apiAction({
  url: `${options.APIURL}/api/shoppingcart/search/${encodeURIComponent(searchItem)}/${lang}`,
  method: "POST",
  data: postBody,
  onSuccess: (response) => {
    onSuccess(response);
    return {
      type: actionTypes.SEARCH_PRODUCTS,
      payload: response
    };
  },
  onFailure: (error) => {
    if (onFailure) {
      onFailure(error);
    }
    console.log("searchProducts api error");
  },
  onFinally: () => {
    onFinally();
  },
  accessToken: access_token
});

export const getProductRedirectURL = (access_token, productID, onSuccess = (res) => {}, onFailure = (e) => {}, onFinally = () => {}) => apiAction({
  url: `${options.APIURL}/api/shoppingcart/redirect/${productID}/false`,
  onSuccess: (response) => {
    onSuccess(response);
    return {
      type: actionTypes.GET_PRODUCT_REDIRECT_URL,
      payload: response
    };
  },
  onFailure: (error) => {
    if (onFailure) {
      onFailure(error);
    }
    console.log("getProductRedirectURL api error");
  },
  onFinally: () => {
    onFinally();
  },
  accessToken: access_token
});