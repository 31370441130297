import { merge } from 'lodash'
const env = process.env.REACT_APP_STAGE || 'development';

const baseConfig = {
  env,
  isDev: env === 'development',
  isTest: env === 'testing',
  isProduction: env === 'production',
  port: 3000
}

let envConfig = {}

switch (env) {
  case 'dev':
  case 'development':
  case 'staging':
    envConfig = require('./dev').config
    break  
  case 'prod':
  case 'production':
    envConfig = require('./prod').config
    break
  default:
    envConfig = require('./dev').config
}


export default merge(baseConfig, envConfig)
