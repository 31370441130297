
import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { debounce, isPlainObject } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faAngleLeft } from '@fortawesome/pro-light-svg-icons';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { searchProducts, getFeaturedProducts, getBaselineLoad } from '../../store/actions';
import CustomCarousel from '../Carousel/CustomCarousel';
import FeaturedProductsCarousel from '../Carousel/FeaturedProductsCarousel';
import ProductList from '../../containers/ProductList/ProductList';
import MainCategoryListContainer from '../../components/MainCategoryListContainer/MainCategoryListContainer';
import { getCookie, deleteCookie } from '../../utils/cookieUtil';

class Search extends Component {

  constructor(props) {
    super(props);

    this.state = {
      searchValue: '',
      lastSearch: {
        value: '',
        results: []
      },
      suggestions: [],
      showResultsView: false,
      showCategories: false,
      publicCategories: [],
      memberCategories: [],
      noResultsFound: false,
      userIsSearching: false,
      showFooter: false
    };

    this.onSuggestionsFetchRequested = debounce(this.onSuggestionsFetchRequested.bind(this), 250);
  }

  async componentDidMount() {
    if (this.props.marketAndCustomerLevelID) {
      if (this.props.location.speaker) {
        const speaker = this.props.location.speaker;
        this.setState({ searchValue: speaker });
        this.input.focus();
        this.onSuggestionsFetchRequested({ value: speaker });
      }

      const isGuest = getCookie('is_guest');

      if (isGuest) {
        const country = JSON.parse(getCookie('guest_country'));
        if (!country.countryValue || !country.customerLevelID || !country.marketID) {
          deleteCookie("access_token");
          deleteCookie("cart_guid");
          deleteCookie("selected_lang");
          deleteCookie("guest_country");
          deleteCookie("guest_region");
          deleteCookie("is_guest");
          deleteCookie("is_quick_buy");
          this.props.history.push('/login');
        }
        this.props.getBaselineLoad(this.props.accessToken, country.countryValue);
        this.props.getFeaturedProducts(this.props.accessToken, this.props.langApi, country.customerLevelID, country.marketID, ((response) => {
          this.setState({ showFooter: true });
        }).bind(this));
      } else {
        this.props.getBaselineLoad(this.props.accessToken, '');
        this.props.getFeaturedProducts(this.props.accessToken, this.props.langApi, null, null, ((response) => {
          this.setState({ showFooter: true });
        }).bind(this));
      }
    }
  }

  componentDidUpdate = async (prevProps) => {
    if (prevProps.baselineLoad !== this.props.baselineLoad ||
      prevProps.langApi !== this.props.langApi ||
      prevProps.marketAndCustomerLevelID !== this.props.marketAndCustomerLevelID) {
      const memberOnlyItems = this.props.baselineLoad[1].parentCategoryIds.filter((categoryItem) => {
        return categoryItem.memberOnly;
      });
      const publicOnlyItems = this.props.baselineLoad[1].parentCategoryIds.filter((categoryItem) => {
        return !categoryItem.memberOnly;
      });
      publicOnlyItems.push({
        parentCategoryID: null, parentCategoryName: "merchandise", memberOnly: false, parentCategoryImage: null, childCategories: []
      });
      this.setState({ publicCategories: publicOnlyItems, memberCategories: memberOnlyItems });

      const isGuest = getCookie('is_guest');
      if (isGuest) {
        const country = JSON.parse(getCookie('guest_country'));
        if (!country.customerLevelID || !country.marketID) {
          deleteCookie("access_token");
          deleteCookie("cart_guid");
          deleteCookie("selected_lang");
          deleteCookie("guest_country");
          deleteCookie("guest_region");
          deleteCookie("is_guest");
          deleteCookie("is_quick_buy");
          this.props.history.push('/login');
        }
        this.props.getFeaturedProducts(this.props.accessToken, this.props.langApi, country.customerLevelID, country.marketID);
      } else {
        this.props.getFeaturedProducts(this.props.accessToken, this.props.langApi);
      }
    }
  };

  storeInputReference = autosuggest => {
    if (autosuggest !== null) {
      this.input = autosuggest.input;
    }
  };

  handleChange = (event, { newValue, method }) => {
    if (event.type === "keydown") {
      this.setState({ searchValue: newValue });
      return;
    }

    if (event.type !== "change") return;

    this.setState({ searchValue: event.target.value, showResultsView: false });
    if (event.target.value !== undefined && event.target.value.length > 0) {
      this.setState({ suggestions: [], userIsSearching: true, noResultsFound: false });
    } else {
      this.setState({ suggestions: [], userIsSearching: false, noResultsFound: false });
    }
  };

  getSuggestionValue = (suggestion) => {
    if (suggestion.header) return suggestion.header;

    return suggestion.productName;
  };

  onSuggestionsClearRequested = () => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) { return; }
    this.setState({ suggestions: [], userIsSearching: false, noResultsFound: false });
  };

  onSuggestionsFetchRequested = ({ value, reason }) => {
    if (reason === 'suggestion-selected') {
      const suggestionHeader = this.state.suggestions[0];
      if (suggestionHeader && suggestionHeader.header === value) {

        this.onSuggestionHeaderClick();
        return;
      }
      this.onSuggestionClick(this.state.suggestions.filter(suggestion => suggestion.productName === value).shift());
      return;
    }

    if (reason === 'input-focused' && value !== '') this.setState({ userIsSearching: true });

    if (value === this.state.lastSearch.value) {
      if (this.state.lastSearch.results === null) {
        this.setState({ suggestions: [], noResultsFound: true, userIsSearching: true, showResultsView: false });
      } else {
        this.setState({ suggestions: this.state.lastSearch.results, noResultsFound: false });
      }

      return;
    }

    let postBody = {
      audio: true,
      book: true,
      video: true,
      audiobook: true,
      790: true
    };

    if (this.props.isGuest) {
      const country = JSON.parse(getCookie('guest_country'));

      if (!country.customerLevelID || !country.marketID) {
        deleteCookie("access_token");
        deleteCookie("cart_guid");
        deleteCookie("selected_lang");
        deleteCookie("guest_country");
        deleteCookie("guest_region");
        deleteCookie("is_guest");
        deleteCookie("is_quick_buy");
        this.props.history.push('/login');
      }

      postBody = {
        ...postBody,
        customerLevelID: country.customerLevelID,
        marketID: country.marketID
      };
    }

    if (value.trim() !== "") {
      this.props.searchProducts(value.trim(), this.props.accessToken, this.props.langApi, postBody, ((response) => {
        let suggestions = [];

        if (response) {
          suggestions = response;
        }

        if (suggestions.length < 1) {
          this.setState({ suggestions: [], noResultsFound: true, lastSearch: { value: value, results: null } });
        } else {
          suggestions.unshift({ header: value });
          this.setState({ suggestions: suggestions, noResultsFound: false, lastSearch: { value: value, results: suggestions } });
        }
      }).bind(this), ((error) => {
        this.setState({ suggestions: [], noResultsFound: true, lastSearch: { value: value, results: null } });
      }).bind(this));
    }
  };

  onSuggestionHeaderClick = () => {
    this.setState({ suggestions: [], userIsSearching: false, noResultsFound: false, showResultsView: true });
  };

  onSuggestionClick = (item) => {
    if (item === undefined) return;

    let parentCategoryID = this.props.match.params.categoryId;
    if (!parentCategoryID) {
      parentCategoryID = item.parentCategoryID;
    }
    // change routeID if promotional or featured items
    if (parentCategoryID === '793' || parentCategoryID === '792') {
      this.props.history.push(`/category/${parentCategoryID}/product-detail/${item.productSKU}/${parentCategoryID}`);
      return;
    }

    this.props.history.push(`/category/${parentCategoryID}/product-detail/${item.productSKU}/${item.productCategoryID}`);
  };

  renderSuggestion = suggestion => {
    if (suggestion.header) {
      return (
        <div className="suggestion-header" onClick={() => this.onSuggestionHeaderClick()}>
          <FontAwesomeIcon style={{ alignSelf: "center", justifySelf: "center" }} data-dismiss="modal" icon={faSearch} size="2x" />
          <div className="suggestion-product-name" >Search for "{suggestion.header}"</div>
        </div>
      );
    }

    return (
      <div className="suggestion-container" onClick={() => this.onSuggestionClick(suggestion)}>
        <img src={suggestion.productImage} className="suggestion-img" />
        <div className="suggestion-product-name" >{suggestion.productName}</div>
        <div className="suggestion-category-text" >{this.props.t(`categories.${suggestion.parentCategoryName}`)}</div>
      </div>
    );
  };

  onFilterCategoryItems = (parentCategory, value) => {
    return value.filter((searchResult) => {
      return searchResult.parentCategoryName === parentCategory;
    });
  };

  onCategoryClick = (category) => {
    const categoryItems = this.props.searchProductResults.filter((product) => {
      return product.parentCategoryID === category;
    });
    this.setState({ categoryItems: categoryItems, showCategories: true });
  };

  onGoBackHandler = () => {
    this.setState({ showCategories: false });
  };

  ResultsView = (props) => {

    let headers;
    let ids;

    if (props.suggestions) {
      headers = props.suggestions
        .map((suggestion) => suggestion.parentCategoryName)
        .filter((suggestion, index, _arr) => _arr.indexOf(suggestion) === index);

      ids = props.suggestions
        .map((suggestion) => suggestion.parentCategoryID)
        .filter((suggestion, index, _arr) => _arr.indexOf(suggestion) === index);

      return headers.map((category, i) => {
        if (category === undefined) return ('');
        return (
          <div className="search-main-content__search-result-products">
            <div className="search-main-content__headers">
              <h1 className="search-main-content__top-products-title">{props.t(`categories.${category}`)}</h1>
              <p onClick={() => props.onCategoryHandler(ids[i])}>{props.t('subcategories.seeall')}</p>
            </div>
            <CustomCarousel
              parentCategoryID={this.props.match.params.categoryId}
              products={props.onFilterCategoryItemsHandler(category, props.suggestions)}
            />
          </div>
        );
      });
    }
    return ('');
  };

  showHeader = () => {
    const { hideNav } = this.props;
    if (!!hideNav)
      return false;
    else
      return true;
  };

  render() {
    const { searchValue, suggestions, showFooter, showResultsView } = this.state;
    const { searchProductResults, featuredProducts, t } = this.props;

    const inputProps = {
      placeholder: t('home.search'),
      value: searchValue,
      onChange: this.handleChange
    };

    return (
      <>
        {
          this.showHeader()
            ? (<Header isGuest={this.props.isGuest} />)
            : (<div className="quick-buy-spacer"></div>)
        }
        <Container className="search-main-content">
          {
            this.state.showCategories ? (
              <>
                <div className="search-main-content__category-header">
                  <FontAwesomeIcon icon={faAngleLeft} size="4x" />
                  <p onClick={() => this.onGoBackHandler()}>{t('subcategories.goback')}</p>
                </div>
                <Row>
                  <ProductList
                    category={'category-view-img'}
                    products={this.state.categoryItems}
                  />
                </Row>
              </>
            ) : (
              <>
                <Autosuggest
                  alwaysRenderSuggestions={true}
                  focusInputOnSuggestionClick={false}
                  highlightFirstSuggestion={true}
                  className="search-main-content__search-bar"
                  suggestions={suggestions}
                  onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                  onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                  getSuggestionValue={this.getSuggestionValue}
                  renderSuggestion={this.renderSuggestion}
                  inputProps={inputProps}
                  ref={this.storeInputReference}
                />
                <br />
                <br />
                <br />
                <br />
                {this.state.userIsSearching ? (
                  <>
                    {this.state.noResultsFound ? <h1 className="message-error">Sorry, No results found for "{this.state.searchValue}".</h1> :
                      this.state.suggestions.length >= 1 ? '' :
                        <h1 className="message-loading">Loading <div className="lds-ring"><div></div><div></div><div></div><div></div></div> </h1>}
                  </>
                ) : ('')}
                {!this.state.userIsSearching && !showResultsView ? (
                  <>
                    <div className="search-main-content__top-products animated fadeIn">
                      <h1 className="search-main-content__top-products-title">{t('search.topsellingproducts')}</h1>
                      <FeaturedProductsCarousel
                        id="additional"
                        products={featuredProducts}
                      />
                    </div>
                    <div className="search-main-content__categories animated fadeIn">
                      <Row>
                        <Col sm={10}>
                          <h1 className="search-main-content__top-products-title">{t('search.categories')}</h1>
                        </Col>
                      </Row>
                      <MainCategoryListContainer
                        homeContainerTitle={'Categories'}
                        categoryItems={this.state.publicCategories}
                      />
                    </div>
                    {
                      this.props.userDetails && this.props.userDetails.length > 0 && this.props.userDetails[0].IsMember === 0 ? (
                        ''
                      ) : (
                        <div className="main-content__member">
                          <hr />
                          <MainCategoryListContainer
                            homeContainerTitle={'Membery Categories Only'}
                            categoryItems={this.state.memberCategories}
                          />
                        </div>
                      )
                    }
                  </>
                ) : (
                  <>
                    {showResultsView && <this.ResultsView
                      t={t}
                      suggestions={searchProductResults}
                      onCategoryHandler={this.onCategoryClick}
                      onFilterCategoryItemsHandler={this.onFilterCategoryItems} />}
                  </>
                )}
              </>
            )
          }
        </Container>
        {showFooter && <Footer />}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    baselineLoad: state.productCategoryReducer.baselineLoad,
    searchProductResults: state.productSearchReducer.searchProductList,
    featuredProducts: state.productCategoryReducer.featuredProducts,
    marketAndCustomerLevelID: state.userReducer.marketAndCustomerIdParam,
    langApi: state.userReducer.langApiParam
  };
};

export default connect(mapStateToProps, {
  searchProducts,
  getFeaturedProducts,
  getBaselineLoad
})(withTranslation()(Search));