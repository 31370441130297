export const deleteCookie = (name) => {
    if (process.env.REACT_APP_STAGE !== "development") {
        var urlComponents = location.hostname.split('.');
        var baseUrl = urlComponents.slice(-2).join('.');
        document.cookie = name + '=; Max-Age=-99999999;secure;path=/;domain=' + baseUrl;
    }
    else {
        document.cookie = name + '=; Max-Age=-99999999;path=/;';
    }
}

export const setCookie = (name, value, days) => {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    if (process.env.REACT_APP_STAGE !== "development") {
        var urlComponents = location.hostname.split('.');
        var baseUrl = urlComponents.slice(-2).join('.');
        document.cookie = name + "=" + (value || "") + expires + "; path=/;secure;domain=" + baseUrl;
    } else {
        document.cookie = name + "=" + (value || "") + expires + ";path=/;";
    }
}

export const getCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}
