import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSync } from '@fortawesome/pro-light-svg-icons';
import { deleteCartItem, updateCartItem } from '../../store/actions';
import { getCookie, deleteCookie } from '../../utils/cookieUtil';

class CartRow extends Component {

    state = {
        newQty: null,
        isDeleting: false
      }

    onQtyNumberInputChange = () => {
        const numberInput = document.getElementById('numberInput');
        if (numberInput && numberInput.value && numberInput.value != this.props.qty) {
            this.setState({ newQty: numberInput.value });
        } else {
            this.setState({ newQty: null });
        }
    }

    onQtyDropdownChange = (e) => {
        if (e && e.value) {
            if (this.props.qty == e.value) {
                this.setState({ newQty: null });
            } else {
                if (e.value == '10+') {
                    this.setState({ newQty: '11' });
                } else {
                    this.setState({ newQty: e.value });
                }
            }
        }
    }

    QuantityInput = ({qty, isDigital}) => {
        if (qty > 10) {
            return (
                <input id="numberInput" type="number" className="quantity form-control input-lg pull-right cart-number-input"
                    onChange={this.onQtyNumberInputChange} defaultValue={qty} />
            );
        } else {
            let options;
            if (isDigital) {
                options = ['0', '1'];
            } else {
                options = ['0', '1', '2', '3', '4','5', '6', '7', '8', '9', '10', '10+'];
            }

            return (
                <Dropdown
                    options={options}
                    onChange={this.onQtyDropdownChange}
                    value={qty.toString()} />
            );
        }
    }

    sendToLoginScreen = () => {
        deleteCookie("access_token");
        deleteCookie("cart_guid");
        deleteCookie("selected_lang");
        deleteCookie("guest_country");
        deleteCookie("guest_region");
        deleteCookie("is_guest");
        deleteCookie("is_quick_buy");
        this.props.history.push('/login');
        return;
    }

    deleteItem = (id) => {
        let access_token = getCookie('access_token');

        if (!access_token) {
            this.sendToLoginScreen();
            return;
        }

        let cart_guid;

        if (this.props.isGuest) {
            cart_guid = getCookie('cart_guid');
        } else {
            if (this.props.userCartGuid && this.props.userCartGuid.cartID) {
                cart_guid = this.props.userCartGuid.cartID;
            }
        }

        if (!cart_guid) {
            this.sendToLoginScreen();
            return;
        }

        this.setState({isDeleting: true});

        this.props.deleteCartItem(id, access_token, cart_guid, this.props.langApi ?? "en", ((response) => {
            this.props.onDelete();
        }).bind(this), null, (() => {
            this.setState({isDeleting: false});
        }).bind(this));
    }

    updateItem = (id) => {
        let access_token = getCookie('access_token');

        if (!access_token) {
            this.sendToLoginScreen();
            return;
        }

        let cart_guid;

        if (this.props.isGuest) {
            cart_guid = getCookie('cart_guid');
        } else {
            if (this.props.userCartGuid && this.props.userCartGuid.cartID) {
                cart_guid = this.props.userCartGuid.cartID;
            }
        }

        if (!cart_guid) {
            this.sendToLoginScreen();
            return;
        }

        this.props.updateCartItem(id, access_token, cart_guid, this.props.langApi ?? "en", this.state.newQty, ((response) => {
            this.setState({ newQty: null });
            this.props.onUpdate();
        }).bind(this));
    }

    onDeleteHandler = (id) => {
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div style={{width: "300px"}} className="modal modal-hover modal-dialog modal-dialog-centered modal-product-image" role="document">
                    <div className="modal-content modal-product-image__wrapper cart-img">
                        <div className="modal-product-image__header">
                            <h1 style={{color: "white"}}>Are you sure?</h1>
                            <div style={{ cursor: "pointer" }} onClick={() => onClose()}>
                            <FontAwesomeIcon icon={faTimes} size="1x" />
                            </div>
                        </div>
                        <div className="modal-product-image__buttons">
                            <button className="btn btn-primary" onClick={onClose}>Cancel</button>
                            <button style={{color: "white"}} onClick={() => {
                                this.deleteItem(id);
                                onClose();
                                }} className="btn btn-danger">
                                Delete
                            </button>
                        </div>
                    </div>
                </div>


              );
            }
          });
      };

    onUpdateHandler = (id) => {
        this.updateItem(id);
    }

    render() {

        const {
            imageURL,
            productName,
            isDigital,
            productSKU,
            price,
            pv,
            qty,
            productID
        } = this.props;

        return (
            <li className="cart__cell">
                <div className="cart__cell-content">
                    <img src={imageURL} className="cart__img" />
                    <div className="cart-product-area">
                        <div style={{alignSelf: 'center'}}>
                            <div className="cart-product-title">{productName}</div>
                            <div className="cart-product-details">
                                <div className="cart-product-detail">
                                    <div className="cart-product-header">Format:</div>
                                    <div className="cart-small-text">{isDigital ? 'Digital' : 'Physical'}</div>
                                </div>
                                <div className="cart-product-detail">
                                    <div className="cart-product-header" style={{paddingLeft: "10px"}}>SKU:</div>
                                    <div className="cart-small-text">{productSKU}</div>
                                </div>
                            </div>
                        </div>
                        <div className="cart-delete-and-qty">
                            <div className="cart-dropdown">{<this.QuantityInput qty={qty} isDigital={isDigital}/>}</div>
                            <div className="cart-update-and-delete">
                                {this.state.newQty && <button onClick={() => this.onUpdateHandler(productID)} className="btn btn-primary cart-button-danger">
                                    Update
                                </button>}
                                <button onClick={() => this.onDeleteHandler(productID)} className="btn btn-danger cart-button-danger">
                                {this.state.isDeleting ? <FontAwesomeIcon icon={faSync} size="1x" spin />: 'Delete'}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="cart-detail-text">{price}</div>
                    <div className="cart-detail-text">{pv}</div>
                </div>
            </li>
        )
    }
}

const mapStateToProps = (state) => {
    return {
      langApi: state.userReducer.langApiParam,
      userCartGuid: state.userReducer.userCartGuid
    }
  }

export default connect(mapStateToProps, {
    deleteCartItem,
    updateCartItem
  })(withRouter(withTranslation()(CartRow)));
  