import React, { Component } from 'react';
import MediaListItem from './MediaListItem';

class MediaList extends Component {
  state = {
    currentSongURL: undefined,
    currentIdx: null
  }

  pauseTrack = (idx) => {
    if (this.state.currentSongURL) {
      this.setState({ currentIdx: idx })
      this.state.currentSongURL.pause();
    }
  }


  playTrackAudio = (audioURL, idx) => {
    this.setState({ currentIdx: idx })

    let audio = new Audio(audioURL)

    if (!this.state.currentSongURL) {
      this.setState({ currentSongURL: audio })
      audio.play();
      return
    }

    if (this.state.currentSongURL.src !== audioURL) {
      this.state.currentSongURL.pause();

      this.setState({ currentSongURL: audio })
      audio.play();
      return;
    }

    audio.play();
  }

  renderMediaListItems = () => {
    const {productMedia} = this.props

    if (productMedia) {
      return productMedia.map((item, index) => {
        return <MediaListItem
          currentIdx={this.state.currentIdx}
          idx={index}
          key={index}
          pauseTrack={(index) => this.pauseTrack(index)}
          playTrackAudio={(songURL, index) => this.playTrackAudio(songURL, index)}
          audioItem={item} />
      })
    }
  }

  render() {
    return (
        <div className="modal-audio-list" role="document">
          <div className="modal-content modal-audio-list__wrapper">
            {this.renderMediaListItems()}
          </div>
        </div>
    )
  }
}

export default MediaList;
