import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router"
import { withTranslation } from 'react-i18next';
import { getBaselineLoad, getFeaturedProducts, getPromotionalProducts } from '../store/actions'
import { getCookie, deleteCookie } from '../utils/cookieUtil';

class NoProductFound extends Component {

  onCountryShoppingHandler = () => {
    let access_token = getCookie('access_token');
    let isGuest = getCookie('is_guest');
    if (isGuest) {
      const country = JSON.parse(getCookie('guest_country'));
      if (!country.countryValue || !country.customerLevelID  || !country.marketID) {
        deleteCookie("access_token");
        deleteCookie("cart_guid");
        deleteCookie("selected_lang");
        deleteCookie("guest_country");
        deleteCookie("guest_region");
        deleteCookie("is_guest");
        deleteCookie("is_quick_buy");
        this.props.history.push('/login');
      }
      this.props.getBaselineLoad(access_token, country.countryValue);
      this.props.getFeaturedProducts(access_token, this.props.langApi, country.customerLevelID, country.marketID);
      this.props.getPromotionalProducts(access_token, this.props.langApi, country.customerLevelID, country.marketID);
    } else {
      this.props.getBaselineLoad(access_token, '');
      this.props.getFeaturedProducts(access_token, this.props.langApi);
      this.props.getPromotionalProducts(access_token, this.props.langApi);
    }
    if (!isGuest && this.props.userDetails[0].allowToBuy === false) {
      this.props.history.push('/category/790');
    } else this.props.history.push('/')
  }


  render() {
    const {
      t
    } = this.props;
    return (
      <div className="no-product-available">
        <h1>{t('error.productnotfound')}</h1>
        <button
          onClick={() => this.onCountryShoppingHandler()}
          className="btn btn-primary">{t('error.homepageredirect')}</button>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    langApi: state.userReducer.langApiParam,
    country: state.userReducer.selectedCountry,
    userDetails: state.userReducer.userDetails
  }
}

export default connect(mapStateToProps, {
  getBaselineLoad,
  getFeaturedProducts,
  getPromotionalProducts
})(withRouter(withTranslation()(NoProductFound)));