import * as actionTypes from '../actions/actionTypes';

export const userReducer = (state = { loginUser: {}, userCartGuid: {} }, action) => {
  switch (action.type) {

    case actionTypes.SET_CUSTOMER_AND_MARKET_ID_PARAM:
      return {
        ...state,
        marketAndCustomerIdParam: action.payload
      }

    case actionTypes.SET_COUNTRY_INDEX:
      return {
        ...state,
        selectedCountryIndex: action.payload
      }

    case actionTypes.SET_LANG_API_PARAM:
      return {
        ...state,
        langApiParam: action.payload
      }

    case actionTypes.NUMBER_CART_ITEMS:
      return {
        ...state,
        numberCartItems: action.payload
      }

    case actionTypes.SELECTED_COUNTRY:
      return {
        ...state,
        selectedCountry: action.payload
      }

    case actionTypes.SET_CURRENCY:
      return {
        ...state,
        currency: action.payload
      }

    case actionTypes.LOGIN_USER:
      return {
        ...state,
        loginUser: action.payload
      }
      
    case actionTypes.ADD_ITEM_TO_CART:
      return {
        ...state,
        userCart: action.payload
      }

    case actionTypes.GET_USER_CART:
      return {
        ...state,
        userCartItems: action.payload
      }

    case actionTypes.GET_CART_TOTALS:
      return {
        ...state,
        userCartTotals: action.payload
      }

    case actionTypes.DELETE_CART_ITEM:
      return {
        ...state,
        deletedCartItem: action.payload
      }

    case actionTypes.GET_CART_GUID:
      return {
        ...state,
        userCartGuid: action.payload
      }

    case actionTypes.VERIFY_TOKEN:
      return {
        ...state,
        isTokenValid: action.payload
      }

    case actionTypes.GET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload
      }

    case actionTypes.SET_USER_TOKEN:
      return {
        ...state,
        accessToken: action.payload
      }

    case actionTypes.API_START:
      if (action.payload === actionTypes.GET_PRODUCT_DETAIL_BY_SKU) {
        return {
          ...state,
          isLoadingProductDetails: true
        }
      } else if (action.payload === actionTypes.GET_CUSTOMER_ENROLL_WITH_PRODUCT_LINK) {
        return {
          ...state,
          isGeneratingCustomerEnrollWithProductLink: true
        }
      } else {
        return {
          ...state,
          isLoading: true
        }
      }

    case actionTypes.API_END:
      if (action.payload === actionTypes.GET_PRODUCT_DETAIL_BY_SKU) {
        return {
          ...state,
          isLoadingProductDetails: false
        }
      } else if (action.payload === actionTypes.GET_CUSTOMER_ENROLL_WITH_PRODUCT_LINK) {
        return {
          ...state,
          isGeneratingCustomerEnrollWithProductLink: false
        }
      } else {
        return {
          ...state,
          isLoading: false
        }
      }

    default:
      return state;
  }
}

export default userReducer;