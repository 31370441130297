import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import reducers from './store/reducers';
import apiMiddleware from "./store/middleware/api";
import App from './App';
import './i18n';
import './static/fontawesome-pro-5-web/css/all.css';
import './static/sass/index.scss';

// Redux config
const store = createStore(reducers, applyMiddleware(apiMiddleware));
export default store;

const app = (
  <Provider store={store}>
    <App />
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
