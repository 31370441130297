import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons'

const ErrorMessageModal = ({ t, message, onClickHandler }) => {
    return (
      <div className="modal modal-hover modal-dialog modal-dialog-centered modal-product-image" role="document">
        <div className="modal-content modal-product-image__wrapper cart-img">
          <div className="modal-product-image__header">
            <h1>{t('error.errortitle')}</h1>
            <div style={{ cursor: "pointer" }} onClick={() => onClickHandler()}>
              <FontAwesomeIcon icon={faTimes} size="1x" />
            </div>
          </div>
          <div className="product-description__product-pv">
            <p>{(message === null || message === undefined) ? '' : message }</p>
          </div>
          <div className="modal-product-image__buttons">
            <button onClick={() => onClickHandler()} className="btn btn-primary">
              {t('home.ok')}
            </button>
          </div>
        </div>
      </div>
    )
  }

export default ErrorMessageModal;
