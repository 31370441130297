import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { getUserCart, getCartTotals, getUserDetails, setNumberCartItems } from '../../store/actions';
import { deleteCookie, getCookie } from '../../utils/cookieUtil';
import CartRow from './CartRow';
import Header from '../../components/Header';
import LoadingIndicator from '../../components/LoadingIndicator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSync
} from '@fortawesome/pro-light-svg-icons';

class Cart extends Component {

  state = {
    cartItems: [],
    itemCount: 0,
    subTotal: "",
    pv: "",
    isLoading: false,
    isCalculatingTotal: false
  };

  sendToLoginScreen = () => {
    deleteCookie("access_token");
    deleteCookie("cart_guid");
    deleteCookie("selected_lang");
    deleteCookie("guest_country");
    deleteCookie("guest_region");
    deleteCookie("is_guest");
    deleteCookie("is_quick_buy");
    this.props.history.push('/login');
    return;
  };

  getCartData = () => {
    this.setState({ isLoading: true });
    let access_token = getCookie('access_token');

    if (!access_token) {
      this.sendToLoginScreen();
    }

    if (this.props.isGuest) {
      const cart_guid = getCookie('cart_guid');
      console.log('this.props ', this.props);
      const customerLevelID = this.props.marketAndCustomerLevelID.customerLevelID;
      this.props.getUserCart(access_token, cart_guid, customerLevelID, ((response) => {
        const userCart = response;

        if (userCart) {
          let totalItems = this.getCartItemCount(userCart);
          this.props.setNumberCartItems(totalItems);
          this.setState({ cartItems: userCart, itemCount: totalItems, isCalculatingTotal: true });

          const country = JSON.parse(getCookie('guest_country'));
          if (!country || !country.countryValue) {
            this.sendToLoginScreen();
            return;
          }

          this.props.getCartTotals(access_token, cart_guid, customerLevelID, country.countryValue, "", ((response) => {
            const cartTotal = response;

            if (cartTotal) {
              this.setState({ subTotal: cartTotal.SubTotal, pv: cartTotal.PV });
            }
          }).bind(this), null, (() => {
            this.setState({ isCalculatingTotal: false });
          }).bind(this));
        } else {
          this.props.setNumberCartItems(0);
          this.setState({
            cartItems: [],
            itemCount: 0,
            subTotal: "",
            pv: ""
          });
        }
      }).bind(this), null, (() => {
        this.setState({ isLoading: false });
      }).bind(this));
    } else {
      this.props.getUserDetails(access_token, ((response) => {
        const userDetails = response;

        let cart_guid;
        if (this.props.userCartGuid && this.props.userCartGuid.cartID) {
          cart_guid = this.props.userCartGuid.cartID;
        }

        if (!cart_guid) {
          this.setState({ isLoading: false });
          return;
        }

        if (userDetails[0]) {
          const region = userDetails[0].Region;
          const country = userDetails[0].Country;
          const customerLevelID = userDetails[0].CustomerLevelID;
          this.props.getUserCart(access_token, cart_guid, customerLevelID, ((response) => {
            const userCart = response;

            if (userCart) {
              let totalItems = this.getCartItemCount(userCart);
              this.props.setNumberCartItems(totalItems);
              this.setState({ cartItems: userCart, itemCount: totalItems, isCalculatingTotal: true });

              this.props.getCartTotals(access_token, cart_guid, customerLevelID, country, region, ((response) => {
                const cartTotal = response;

                if (cartTotal) {
                  this.setState({ subTotal: cartTotal.SubTotal, pv: cartTotal.PV });
                }
              }).bind(this), null, (() => {
                this.setState({ isCalculatingTotal: false });
              }).bind(this));
            } else {
              this.props.setNumberCartItems(0);
              this.setState({
                cartItems: [],
                itemCount: 0,
                subTotal: "",
                pv: ""
              });
            }
          }).bind(this), null, (() => {
            this.setState({ isLoading: false });
          }).bind(this));
        }
      }).bind(this));
    }
  };

  async componentDidMount() {
    this.getCartData();
  }

  getCartItemCount = (userCart) => {
    let totalItems = 0;
    if (userCart.length > 1) {
      totalItems = userCart.reduce((sum, number) => {
        return sum + parseInt(number.Quantity, 10);
      }, 0);
    } else {
      totalItems = parseInt(userCart[0].Quantity);
    }

    return totalItems;
  };

  renderCartItemRows = () => {
    const { cartItems } = this.state;

    if (cartItems) {
      return cartItems.map((item, index) => {
        return <CartRow
          productID={item.ProductID}
          productSKU={item.SKU}
          productName={item.ProductName}
          imageURL={item.ImageUrl}
          isDigital={item.IsDigital}
          price={item.UnitCost}
          pv={item.UnitPV}
          qty={item.Quantity}
          onDelete={() => this.getCartData()}
          onUpdate={() => this.getCartData()}
          key={item.ProductID + item.SKU + index}
          isGuest={this.props.isGuest} />;
      });
    }
  };

  onProceedToCheckoutClickHandler = () => {
    this.props.history.push('/checkout');
    window.location.replace(`${window.location.origin}/checkout`);
  };

  showHeader = () => {
    const { hideNav } = this.props;
    if (!!hideNav)
      return false;
    else
      return true;
  };

  render() {

    return (<>
      {
        this.showHeader()
          ? (<Header isGuest={this.props.isGuest} disableCountryDDL={true} />)
          : (<div className="quick-buy-spacer"></div>)
      }
      { this.state.isLoading ? (<LoadingIndicator />) : (
        <div className="main-content">
          <div className="product-description container">
            <div className="product-description__header row">
              <div className="col">
                <div className="product-description__title-and-sharebutton" style={{ justifyContent: "flex-start " }}>
                  <h1 className="product-description__title-and-sharebutton__title animated fadeIn">Shopping Cart</h1>
                </div>
                {(this.state.cartItems === undefined || this.state.cartItems.length == 0) ? <>
                  <div className="no-product-available">
                    <h2 style={{ fontSize: "x-large" }}>There are no items in your cart!</h2>
                  </div>
                </> : <>
                  <div className="cart__cell-container">
                    <ul className="cart__cell-list">
                      <li className="cart__cell">
                        <div className="cart-header">
                          <div></div>
                          <div>Price</div>
                          <div style={{ textAlign: 'center' }}>PV</div>
                        </div>
                      </li>
                      {this.renderCartItemRows()}
                    </ul>
                  </div>

                  <div className="cart-continue-shopping">
                    <button onClick={() => this.props.history.push('/')} id="btnContinueShopping" className="btn btn-light cart-continue-shopping-button">Continue Shopping</button>
                  </div>
                  <div className="cart-totals-and-button">
                    <div className="cart-totals">
                      <div></div>
                      <div className="cart-subtotal">
                        <div className="cart-subtotal-line1">
                          <div className="cart-subtotal-header">Subtotal</div>
                          <div className="cart-subtotal-subline">({this.state.itemCount} items):</div>
                          <div className="cart-subtotal-price">{this.state.isCalculatingTotal ? <FontAwesomeIcon icon={faSync} size="1x" spin /> : `$${this.state.subTotal}`}</div>
                        </div>
                        <div className="cart-subtotal-line2">
                          <div className="cart-subtotal-subline">({this.state.pv} PV)</div>
                        </div>
                      </div>
                    </div>
                    <div className="cart-subtotal-line3">
                      <button onClick={() => this.onProceedToCheckoutClickHandler()} className="btn btn-primary cart-button">
                        {this.props.t('cartmodal.proceedtocheckout')}
                      </button>
                    </div>
                    <div className="cart-subtotal-line4">
                      <div className="cart-continue-shopping-mobile">
                        <hr id="hrCartButton" style={{ border: "1px solid white" }}></hr>
                        <button onClick={() => this.props.history.push('/')} id="btnContinueShoppingMobile" className="btn btn-light cart-continue-shopping-button">Continue Shopping</button>
                      </div>
                    </div>
                  </div>
                </>}
              </div>
            </div>
          </div>
        </div>
      )}
    </>);
  }
}

const mapStateToProps = (state) => {
  return {
    userCartGuid: state.userReducer.userCartGuid,
    marketAndCustomerLevelID: state.userReducer.marketAndCustomerIdParam
  };
};

export default connect(mapStateToProps, {
  getUserCart,
  getCartTotals,
  getUserDetails,
  setNumberCartItems
})(withRouter(withTranslation()(Cart)));
