import React from 'react'
import OwlCarousel from 'react-owl-carousel2';
import { Link } from 'react-router-dom';

const FeaturedProductsCarousel = ({ products }) => {

  if (!products) return ('')

  const options = {
    items: 4,
    loop: true,
    stagePadding: 25,
    center: false,
    rewind: true,
    autoplay: false,
    margin: 15,
    responsive: {
      0: {
        items: 3,
      },
      350: {
        items: 3,
      },
      750: {
        items: 4,
      },
    },
  };

  return (
    <OwlCarousel options={options}>
      {
        products.map((product, i) => {
          let id = product.parentCategoryID;
          return (
            <div id="category-view-owl" key={id + product.productSKU}>
              <Link className="category-view-img__link" to={`${id}/product-detail/${product.productSKU}/${id}`}>
                <div style={{ backgroundImage: `url(${product.productImage})`, height: "85%"}}   className="item">
                </div>
                <p className="product-list__title line-clamp">{product.productName}</p>
              </Link>
            </div>
          )
        })
      }
    </OwlCarousel>
  )
}

export default FeaturedProductsCarousel;