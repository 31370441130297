import React, { Component  } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSearch,
  faShoppingBag,
  faThList,
  faTimes
} from '@fortawesome/pro-light-svg-icons';

import { getUserCart, setCustomerAndMarketIDParam, setLangApiParam, setNumberCartItems, setCountryIndex, getPromotionalProducts, getFeaturedProducts }from '../../../store/actions';
import { getCookie, setCookie } from '../../../utils/cookieUtil';

class Navbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedLanguage: '',
      languageDisplay: 'english',
      marketIds: [],
      isCartGuidValid: false,
      width: 0,
      cartItems: 0,
      selectedIndex: 2,
      selectedParams: {
        countryName: "",
        countryValue: "",
        currency: "",
        customerLevelID: 0,
        marketID: 0
      },
      marketIds: [],
    }
  }

  async componentDidMount() {
    const cart_guid = getCookie('cart_guid');
    let isValid = false
    if (cart_guid !== null) {
      this.setState({ isCartGuidValid: true})
      isValid = true
    }
    if(this.props.isGuest) {
      this.setState({ selectedIndex: this.props.selectedCountryIndex });
    }
    if (this.props.langApiParam === 'es') {
      this.setState({ languageDisplay: 'spanish', selectedLanguage: 'es' })
    } else if (this.props.langApiParam === 'fr') {
      this.setState({ languageDisplay: 'french', selectedLanguage: 'fr' })
    } else if (this.props.langApiParam === 'en') {
      this.setState({ languageDisplay: 'english', selectedLanguage: 'en' })
    }
    if (this.props.baselineLoad) {
      this.setState({ marketIds: this.props.baselineLoad[0].marketIds })
    }
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    setCookie("selected_lang", this.props.langApiParam, 30);
    if (isValid) {
      this.getCartItemsTotalCount();
    }
    this.props.i18n.changeLanguage(this.props.langApiParam);
  }

  componentDidUpdate = async (prevProps, prevSate) => {
    if(prevProps.userCartGuid !== this.props.userCartGuid) {
      const cart_guid = this.props.userCartGuid.cartID; 
      if(cart_guid) {
        this.setState({ isCartGuidValid: true });
        this.getCartItemsTotalCount();
      }
    }

    if (prevProps.baselineLoad !== this.props.baselineLoad) {
      this.setState({ marketIds: this.props.baselineLoad[0].marketIds })
    }

    if (prevProps.userToken !== this.props.userToken) {
      this.setState({ isCartGuidValid: true });
    }
   
    if (this.props.userCart !== prevProps.userCart && this.state.isCartGuidValid) {
      this.getCartItemsTotalCount();
    }

    if (this.props.numberCartItems !== prevProps.numberCartItems) {
      this.getCartItemsTotalCount();
    }
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  onSearchHandler = () => {
    this.props.history.push({
      pathname: '/search',
      state: { publicCategories: this.props.publicCategories }
    })
  }

  handleLanguageChange = (lang) => {
    this.setState({ selectedLanguage: lang });
  };

  onCountryModalClose = () => {
    const apiParams = {
      marketID: this.state.selectedParams.marketID,
      customerLevelID: this.state.selectedParams.customerLevelID
    }
    if (this.state.selectedLanguage === 'es') {
      this.setState({ languageDisplay: 'spanish' })
    } else if (this.state.selectedLanguage === 'fr') {
      this.setState({ languageDisplay: 'french' })
    } else if (this.state.selectedLanguage === 'en') {
      this.setState({ languageDisplay: 'english' })
    }

    // call baseline load here too
    this.props.i18n.changeLanguage(this.state.selectedLanguage)
    this.props.setLangApiParam(this.state.selectedLanguage);
    setCookie("selected_lang", this.state.selectedLanguage, 30);
    if (this.props.isGuest) {
      this.props.setCountryIndex(this.state.selectedIndex);
      this.props.setCustomerAndMarketIDParam(apiParams);
      setCookie("guest_country", JSON.stringify(this.state.marketIds[this.state.selectedIndex]), 30);
    }
  }

  handleCountryChange = (event) => {
    if (this.props.isGuest) {
      this.setState({
        selectedIndex: event.target.value,
        selectedParams: {
          marketID: this.state.marketIds[event.target.value].marketID,
          customerLevelID: this.state.marketIds[event.target.value].customerLevelID,
          countryName: this.state.marketIds[event.target.value].countryName,
        }
      })
    }
  }

  getCartItemsTotalCount = () => {
    const access_token = getCookie('access_token');
    const isGuest = getCookie('is_guest');
    let cart_guid;

    if (isGuest) {
      cart_guid = getCookie('cart_guid');
    } else {
      cart_guid = this.props.userCartGuid.cartID;
    }
    this.props.getUserCart(access_token, cart_guid, this.props.marketAndCustomerLevelID.customerLevelID, ((response) => {
      if(response !== "" && response) {
        let totalItems = 0;
        if(response.length > 1) {
          totalItems = response.reduce((sum, number) => {
            return sum + parseInt(number.Quantity, 10)
          }, 0)
        }
        else totalItems = parseInt(response[0].Quantity);
        this.setState({ cartItems: totalItems })
      } else {
        this.setState({ cartItems: 0 })
      }
    }).bind(this));

  }

  ChangeLanguageModal = (props) => {
    return (
      <div className="modal-dialog modal-dialog-centered modal-lang-country" role="document">
      <div className="modal-content modal-lang-country__wrapper">
        <div className="modal-lang-country__cross" data-dismiss="modal">
          <FontAwesomeIcon icon={faTimes} size="1x" />
        </div>
        {
          this.props.isGuest ? (
            <div className="modal-lang-country__input-container">
            <h1 className="modal-lang-country__title">{props.t('home.language.selectcountry')}</h1>
            <select 
              disabled={this.props.disableCountryDDL}
              selectedIndex={this.state.selectedIndex}
              className="modal-lang"
              value={this.state.selectedIndex}
              onChange={(e) => this.handleCountryChange(e)}
            >
              {
                this.state.marketIds.map((marketItem, index) => {
                  return <option 
                    key={index}
                    data-marketid={marketItem.marketID}
                    data-customerlevelid={marketItem.customerLevelID}
                    value={index}>{marketItem.countryName}</option>
                })
              }
            </select>
          </div>
          ) : ''
        }

        <div className="modal-lang-country__input-container">
          <h1 className="modal-lang-country__title">{props.t('home.language.language')}</h1>
          <div className="modal-lang-country__form-group">
            <div className="form-check">
              <label className="selectgroup-item">
                <input
                  type="radio"
                  name="react-tips"
                  value="en"
                  checked={props.selectedLanguage === "en"}
                  onChange={() => props.handleLanguageChange("en")}
                  className="form-check-input"
                />
                <span className="input-language">
                  {props.t('home.language.english')}
                </span>
              </label>
            </div>
            <div className="form-check">
              <label className="selectgroup-item">
                <input
                  type="radio"
                  name="react-tips"
                  value="es"
                  checked={props.selectedLanguage === "es"}
                  onChange={() => props.handleLanguageChange("es")}
                  className="form-check-input"
                />
                <span className="input-language">
                  {props.t('home.language.spanish')}
                </span>
              </label>
            </div>
            <div className="form-check">
              <label className="selectgroup-item">
                <input
                  type="radio"
                  name="react-tips"
                  value="fr"
                  checked={props.selectedLanguage === "fr"}
                  onChange={() => props.handleLanguageChange("fr")}
                  className="form-check-input"
                />
                <span className="input-language">
                  {props.t('home.language.french')}
                </span>
              </label>
            </div>
          </div>
        </div>
        <div className="modal-lang-country__button-container">
          <button
            onClick={() => props.onCountryModalClose()}
            type="button"
            className="btn btn-primary" data-dismiss="modal">{props.t('home.ok')}
          </button>
        </div>
      </div>
    </div>
    )
  }

  render() {
    const { t } = this.props
    return (
      <>
        <nav className="header__navbar">
          <div className="header__logo">
            <img
              onClick={() => this.props.history.push('/')}
              src="https://main.secure.footprint.net/public/icon/life.png"
              alt=""
            />
            {
              this.state.width < 600 ? ('') : (
                  <p onClick={() => this.props.history.push('/')}>{t('home.shop')}
                    <FontAwesomeIcon data-dismiss="modal" icon={faShoppingBag} size="1x" />
                  </p>
                )
            }
          </div>

          <div className="header__right-content">
            <div
              onClick={() => this.onSearchHandler()}
              className="header__right-content search-container"
            >
              <FontAwesomeIcon data-dismiss="modal" icon={faSearch} size="2x" />
              <p className="search-container__title">{t('home.search')}</p>
            </div>

            <div
              id="globe"
              data-toggle="modal" data-target="#exampleModal"
              className="globe-container">
              <i className="globe-icon fas fa-globe fa-2x"></i>
              {
                this.state.width < 600 ? (
                  ''
                ) : (
                    <>
                      <p className="search-container__title">{t(`home.language.${this.state.languageDisplay}`)}</p>
                    </>
                  )
              }
            </div>
              {
                this.state.isCartGuidValid ? (
                  <div className="cart-container">
                    <div className="cart-container__notif">{this.state.cartItems}</div>
                    <i onClick={() => { this.props.history.push('/cart');}} className="fas fa-shopping-cart fa-2x"></i>
                    {
                      this.state.width < 500 ? (
                        ''
                        ) : (
                          <span onClick={() => { this.props.history.push('/cart');}}className="username">CART</span>
                      )
                    }
                  </div>
                ) : ('')
                }
          </div>
        </nav>
        <div
          className="modal fade"
          data-backdrop={true} id="exampleModal"
          tabIndex="-1" role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true">
          <this.ChangeLanguageModal
            t={t}
            selectedLanguage={this.state.selectedLanguage}
            handleLanguageChange={this.handleLanguageChange}
            onCountryModalClose={this.onCountryModalClose} />
        </div >
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    langApiParam: state.userReducer.langApiParam,
    baselineLoad: state.productCategoryReducer.baselineLoad,
    userCart: state.userReducer.userCart,
    userToken: state.userReducer.loginUser,
    userCartGuid: state.userReducer.userCartGuid,
    marketAndCustomerLevelID: state.userReducer.marketAndCustomerIdParam,
    numberCartItems: state.userReducer.numberCartItems,
    selectedCountryIndex: state.userReducer.selectedCountryIndex,
  }
}

export default connect(mapStateToProps, {
  getUserCart,
  setCustomerAndMarketIDParam,
  setLangApiParam,
  setNumberCartItems,
  setCountryIndex,
  getFeaturedProducts,
  getPromotionalProducts
})(withTranslation()(withRouter(Navbar)));