import React, { Component } from 'react';
import { connect } from "react-redux";
import Container from 'react-bootstrap/Container';

import ProductDetailDescription from './ProductDetailDescription';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { getProductDetailBySku } from '../../store/actions';
import NoProductFound from '../NoProductFound';
import LoadingIndicator from '../../components/LoadingIndicator';
import { getCookie, deleteCookie } from '../../utils/cookieUtil';

class ProductDetail extends Component {
  state = {
    parentCategory: '',
    updatedAdditionProduct: false,
    parentCategoriesIds: [],
    showFooter: false,
    isErrorLoadingProduct: false
  };

  async componentDidMount() {
    if (this.props.baselineLoad) {
      this.setUpProductDetails();
    }
    window.scrollTo(0, 0);
  }

  setUpProductDetails = () => {
    const categoryID = parseInt(this.props.match.params.categoryId, 10);
    const productCategoryID = parseInt(this.props.match.params.productCategoryID, 10);
    const productSKU = this.props.match.params.productSKU;
    const parentCategory = this.props.baselineLoad[1].parentCategoryIds.filter((category) => {
      return category.parentCategoryID === categoryID;
    });
    this.setState({ parentCategory: parentCategory[0].parentCategoryName, childCategories: parentCategory[0].childCategories });

    let customerLevelID = null;
    let marketID = null;

    if (this.props.isGuest) {
      const country = JSON.parse(getCookie('guest_country'));

      if (!country.customerLevelID || !country.marketID) {
        deleteCookie("access_token");
        deleteCookie("cart_guid");
        deleteCookie("selected_lang");
        deleteCookie("guest_country");
        deleteCookie("guest_region");
        deleteCookie("is_guest");
        deleteCookie("is_quick_buy");
        this.props.history.push('/login');
      }

      customerLevelID = country.customerLevelID;
      marketID = country.marketID;
    }

    this.props.getProductDetailBySku(productSKU, productCategoryID, this.props.accessToken, this.props.langApi, customerLevelID, marketID, ((response) => {
      this.setState({ showFooter: true });
    }).bind(this), ((error) => {
      this.setState({ showFooter: true });
      this.props.getProductDetailBySku(productSKU, categoryID, this.props.accessToken, this.props.langApi, customerLevelID, marketID, (() => { }), ((error) => {
        this.setState({ isErrorLoadingProduct: true });
      }).bind(this));
    }).bind(this));
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.baselineLoad !== this.props.baselineLoad) {
      this.setUpProductDetails();
    }

    if (prevProps.marketAndCustomerLevelID !== this.props.marketAndCustomerLevelID ||
      prevProps.langApi !== this.props.langApi
    ) {
      this.setState({ isErrorLoadingProduct: false });
      this.setUpProductDetails();
    }

    window.onpopstate = (event) => {
      const productSKU = this.props.match.params.productSKU;
      if (productSKU) {
        this.componentDidMount();
      }
    };
  };

  onAdditionalProductHandler = async (product) => {
    const categoryID = parseInt(this.props.match.params.categoryId, 10);
    const productCategoryID = parseInt(this.props.match.params.productCategoryID, 10);
    this.setState({ showFooter: false });
    await this.props.history.push(`/category/${categoryID}/product-detail/${product.productSKU}/${productCategoryID}`);

    const { productSKU } = this.props.match.params;

    let customerLevelID = null;
    let marketID = null;

    if (this.props.isGuest) {
      const country = JSON.parse(getCookie('guest_country'));

      if (!country.customerLevelID || !country.marketID) {
        deleteCookie("access_token");
        deleteCookie("cart_guid");
        deleteCookie("selected_lang");
        deleteCookie("guest_country");
        deleteCookie("guest_region");
        deleteCookie("is_guest");
        deleteCookie("is_quick_buy");
        this.props.history.push('/login');
      }

      customerLevelID = country.customerLevelID;
      marketID = country.marketID;
    }

    this.props.getProductDetailBySku(productSKU, productCategoryID, this.props.accessToken, this.props.langApi, customerLevelID, marketID, ((response) => {
      this.setState({ showFooter: true });
      window.scrollTo(0, 0);
    }).bind(this));
  };

  renderProductDetail = () => {

    if (this.state.isErrorLoadingProduct) return (<NoProductFound />);

    if (this.props.productDetail) {
      if (Object.keys(this.props.productDetail).length >= 1) {
        return (<>
          {
            this.props.isLoadingProductDetails ?
              (<LoadingIndicator />) :
              (<ProductDetailDescription
                parentCategory={this.state.parentCategory}
                onAdditionalProductHandler={(product) => this.onAdditionalProductHandler(product)}
                product={this.props.productDetail}
                accessToken={this.props.accessToken}
                isQuickBuy={this.props.isQuickBuy}
                isGuest={this.props.isGuest}
                categoryId={parseInt(this.props.match.params.categoryId, 10)}
              />)
          }
        </>);
      }
      return (<NoProductFound />);
    }
  };

  showHeader = () => {
    const { hideNav } = this.props;
    if (!!hideNav)
      return false;
    else
      return true;
  };

  render() {
    return (
      <>
        {
          this.showHeader()
            ? (<Header parentCategory={this.state.parentCategory} allParentCategoryIds={this.props.allParentCategoryIds} isGuest={this.props.isGuest} />)
            : (<div className="quick-buy-spacer"></div>)
        }
        <Container className="product-description">
          {this.renderProductDetail()}
        </Container>
        {this.state.showFooter && <Footer />}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    productDetail: state.productDetailReducer.productDetails,
    marketAndCustomerLevelID: state.userReducer.marketAndCustomerIdParam,
    langApi: state.userReducer.langApiParam,
    baselineLoad: state.productCategoryReducer.baselineLoad,
    userDetails: state.userReducer.userDetails,
    isLoadingProductDetails: state.userReducer.isLoadingProductDetails
  };
};

export default connect(mapStateToProps, {
  getProductDetailBySku
}
)(ProductDetail);
