import * as actionTypes from '../actions/actionTypes';

export const productCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_PRODUCTCATEGORY_ITEMS:
      return {
        ...state,
        productDetails: action.payload
      }

    case actionTypes.GET_BASELINE_LOAD:
      return {
        ...state,
        baselineLoad: action.payload
      }

    case actionTypes.GET_CHILD_CATEGORY_ITEMS:
      return {
        ...state,
        childCategoryItems: action.payload
      }

    case actionTypes.SET_PARENT_CATEGORYIDS:
      return {
        ...state,
        parentCategoryIds: action.payload
      }

    case actionTypes.GET_FEATURED_PRODUCTS:
      return {
        ...state,
        featuredProducts: action.payload
      }

    case actionTypes.GET_PROMOTIONAL_PRODUCTS:
      return {
        ...state,
        promotionalProducts: action.payload
      }

    case actionTypes.API_START:
      if (action.payload === actionTypes.GET_PRODUCTCATEGORY_ITEMS) {
        return {
          ...state,
          isLoadingProductCategoryItems: true
        }
      } else if (action.payload === actionTypes.GET_CHILD_CATEGORY_ITEMS) {
        return {
          ...state,
          isLoadingProductChildCategoryItems: true
        }
      } else {
        return {
          ...state,
          isLoading: true
        }
      }

    case actionTypes.API_END:
      if (action.payload === actionTypes.GET_PRODUCTCATEGORY_ITEMS) {
        return {
          ...state,
          isLoadingProductCategoryItems: false
        }
      } else if (action.payload === actionTypes.GET_CHILD_CATEGORY_ITEMS) {
        return {
          ...state,
          isLoadingProductChildCategoryItems: false
        }
      } else {
        return {
          ...state,
          isLoading: false
        }
      }

    default:
      return state;
  }
}

export default productCategoryReducer;