import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getSpeakerAudio, addItemToCart, sendProductEmail, getCustomerEnrollWithProductLink } from '../../store/actions';
import { physicalAndDigitalSKUs } from '../../utils/physicalAndDigitalSKUs';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

import {
  faTimes,
  faPlayCircle,
  faSync,
  faChevronRight
} from '@fortawesome/pro-light-svg-icons';
import MediaList from '../MediaList/MediaList';
import { withRouter } from 'react-router-dom';
import Speaker from '../MediaList/Speaker';
import OwlCarousel from 'react-owl-carousel2';
import { getCookie, deleteCookie } from '../../utils/cookieUtil';
import ErrorMessageModal from '../../components/ErrorMessageModal';
import SuccessMessageModal from '../../components/SuccessMessageModal';

class ProductDetailDescription extends Component {
  state = {
    width: 0,
    showFullText: false,
    mediaURL: [],
    speakerName: '',
    speakerAudio: [],
    height: 0,
    selectedQty: 1,
    showProceedToCheckout: false,
    showShareProductModal: false,
    shareProductRecipientName: '',
    shareProductRecipientEmail: '',
    shareProductMessage: '',
    shareProductShowMessage: false,
    previewShareProductEmail: false,
    errorMessage: null,
    successMessage: null,
    shareProductErrorMessage: null,
    isAddingItemToCart: false,
    physicalAndDigitalDropdownEnabled: false,
    physicalAndDigitalDropdownValue: null
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    if (physicalAndDigitalSKUs[this.props.product.productSKU]) {
      if (this.props.product.productSKU.charAt(0) === 'e') {
        this.setState({ physicalAndDigitalDropdownEnabled: true, physicalAndDigitalDropdownValue: 'Digital' });
      } else {
        this.setState({ physicalAndDigitalDropdownEnabled: true, physicalAndDigitalDropdownValue: 'Physical' });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  truncateText = (text) => {
    let truncatedText;
    if (text) {
      text.length > 700 ? truncatedText = text.substring(0, 560) + '...'
        : truncatedText = text;

      if (this.state.showFullText) {
        truncatedText = text;
      }
    }
    return truncatedText;
  };

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  toggleShowProceedToCheckout = () => {
    this.setState({ showProceedToCheckout: !this.state.showProceedToCheckout });
  };

  proceedToShopHome = () => {
    this.setState({ showProceedToCheckout: !this.state.showProceedToCheckout });
    this.props.history.push('/');
  };

  shareButtonClickHandler = () => {
    const is_desktop = getCookie('is_desktop');
    const nativeapp = getCookie('nativeapp');
    if (is_desktop || (nativeapp == null || !nativeapp)) {
      this.toggleShowShareProductModal();
      return;
    }

    // on ios app
    const access_token = getCookie('access_token');
    this.props.getCustomerEnrollWithProductLink(access_token, this.props.product.productID, this.props.langApi, ((response) => {
      if (!response || !response.url) {
        this.setState({ errorMessage: "Could not generate share link!" });
      }

      window.location.href = `sms://${response.url}`;
    }).bind(this));
  };

  toggleShowShareProductModal = () => {
    this.setState({ showShareProductModal: !this.state.showShareProductModal });
  };

  togglePreviewShareProductEmail = () => {
    this.toggleShowShareProductModal();
    this.setState({ previewShareProductEmail: !this.state.previewShareProductEmail });
  };

  clearErrorMessage = () => {
    this.setState({ errorMessage: null });
  };

  clearShareProductErrorMessage = () => {
    this.setState({ shareProductErrorMessage: null });
  };

  clearSuccessMessage = () => {
    this.setState({ successMessage: null });
    this.resetShareProductModalData();
  };

  onSpeakerModalClick = (speaker) => {

    let customerLevelID = null;
    let marketID = null;

    if (this.props.isGuest) {
      const country = JSON.parse(getCookie('guest_country'));

      if (!country.customerLevelID || !country.marketID) {
        deleteCookie("access_token");
        deleteCookie("cart_guid");
        deleteCookie("selected_lang");
        deleteCookie("guest_country");
        deleteCookie("guest_region");
        deleteCookie("is_guest");
        deleteCookie("is_quick_buy");
        this.props.history.push('/login');
      }

      customerLevelID = country.customerLevelID;
      marketID = country.marketID;
    }

    this.props.getSpeakerAudio(speaker, this.props.accessToken, this.props.langApi, customerLevelID, marketID, ((response) => {
      this.setState({ speakerAudio: response, speakerName: speaker });
    }).bind(this));
  };

  onModalImageClick = (media) => {
    this.setState({ mediaURL: media });
  };

  onModalClose = () => {
    const iframe = document.getElementById('iframeId');
    const videoSrc = iframe.src;
    iframe.src = "";
    iframe.src = videoSrc;
  };

  isProductDigital = () => {
    if (this.props.product.productSKU.charAt(0) === 'e' && this.props.product.productSKU.substring(0, 2) !== 'ed') {
      return 'Digital';
    }
    return 'Physical';
  };
  onQuantityChange = (e) => {
    this.setState({ selectedQty: e.target.value });
  };

  onRecipientNameChange = (e) => {
    this.setState({ shareProductRecipientName: e.target.value });
  };

  onRecipientEmailChange = (e) => {
    this.setState({ shareProductRecipientEmail: e.target.value });
  };

  onShareProductMessageChange = (e) => {
    this.setState({ shareProductMessage: e.target.value });
  };

  toggleShareProductShowMessage = (e) => {
    e.preventDefault();
    this.setState({ shareProductShowMessage: !this.state.shareProductShowMessage });
  };

  resetShareProductModalData = () => {
    this.setState({
      shareProductRecipientName: '',
      shareProductRecipientEmail: '',
      shareProductMessage: ''
    });
  };

  addItem = (productId) => {
    this.setState({ isAddingItemToCart: true });
    const access_token = getCookie('access_token');
    const cart_guid = getCookie('cart_guid');
    this.props.addItemToCart(productId, access_token, cart_guid, this.props.langApi, this.state.selectedQty, ((response) => {
      if (this.props.isQuickBuy) {
        this.onCheckoutClick();
      } else {
        this.setState({ showProceedToCheckout: true });
      }
    }).bind(this), ((error) => {
      let message = "Could not add item to cart!";
      if (error.data && error.data.message && error.data.message !== "") {
        message = this.props.t('error.' + error.data.message);
      }
      this.setState({ errorMessage: message });
    }).bind(this), (() => {
      this.setState({ isAddingItemToCart: false });
    }).bind(this));
  };

  onAddToCart = () => {
    const sku = physicalAndDigitalSKUs[this.props.product.productSKU];
    if (this.state.physicalAndDigitalDropdownValue == 'Both' && sku) {
      this.addItem(sku.PhysicalProductId);
      this.addItem(sku.DigitalProductId);
    } else if (this.state.physicalAndDigitalDropdownValue == 'Physical' && sku) {
      this.addItem(sku.PhysicalProductId);
    } else if (this.state.physicalAndDigitalDropdownValue == 'Digital' && sku) {
      this.addItem(sku.DigitalProductId);
    } else {
      this.addItem(this.props.product.productID);
    }
  };

  onCheckoutClick = () => {
    this.props.history.push('/cart');
  };

  shareProductInEmail = () => {

    const {
      shareProductRecipientName,
      shareProductRecipientEmail,
      shareProductMessage
    } = this.state;

    if (shareProductRecipientName === '' || shareProductRecipientEmail === '') {
      return;
    }

    const data = {
      productID: this.props.product.productID,
      recipientEmail: shareProductRecipientEmail,
      recipientName: shareProductRecipientName,
      message: shareProductMessage,
      language: this.props.langApi
    };

    this.props.sendProductEmail(this.props.accessToken, data, ((response) => {
      this.toggleShowShareProductModal();
      this.setState({ successMessage: 'Your email was sent to: ' + shareProductRecipientEmail });
    }).bind(this), ((error) => {
      this.setState({ shareProductErrorMessage: "Could not send email!" });
    }).bind(this));
  };

  PreviewShareProductModal = ({ t, product }) => {

    const {
      shareProductRecipientName,
      shareProductMessage,
      previewShareProductEmail
    } = this.state;

    if (!previewShareProductEmail) {
      return null;
    }

    return (
      <div className="modal modal-hover modal-dialog modal-dialog-centered modal-product-image-cart" role="document">
        <div id="previewShareProductView" className="modal-content modal-product-image__wrapper cart-img">
          <div>
            <table style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <td>
                    <h4 style={{ fontSize: 'large', paddingBottom: '5px' }}>Here is email preview:</h4>
                  </td>
                  <td style={{ fontSize: 'medium', textAlign: 'right' }}>
                    <button onClick={() => this.togglePreviewShareProductEmail()} className="btn btn-primary" >
                      {t('shareproduct.hidepreview')}
                    </button><br /><br />
                  </td>
                </tr>
              </tbody>
            </table>
            <hr style={{ borderTop: '1px solid white', marginTop: '0', paddingTop: '0' }} />
            <table style={{ width: '100%', textAlign: 'center' }}>
              <tbody>
                <tr>
                  <td><h2 style={{ fontSize: 'x-large', paddingBottom: '15px' }}>{shareProductRecipientName},</h2></td>
                </tr>
                <tr>
                  <td style={{ fontSize: 'medium' }}>{shareProductMessage}<br /><br /></td>
                </tr>
              </tbody>
            </table>
            <table style={{ width: '100%', textAlign: 'center' }}>
              <tbody>
                <tr>
                  <td><img src={product.productMedia[0].imageURL} alt="Promotional Product" height="250"
                    style={{ height: '250', width: '-webkit-fill-available', objectFit: 'contain', paddingBottom: '15px' }} /></td>
                </tr>
                <tr>
                  <td><h2 style={{ fontSize: 'large', paddingBottom: '15px' }}>{product.productName}</h2></td>
                </tr>
                <tr>
                  <td><h2 style={{ fontSize: 'medium' }} dangerouslySetInnerHTML={{
                    __html: product.productSummary
                  }} /></td>
                </tr>
                <tr>
                  <td>
                    <br /><br /><a className="button"><h2 style={{ fontSize: 'medium', color: 'blue' }}>BUY NOW!</h2></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  ShareProductModal = ({ t, product }) => {

    const {
      showShareProductModal,
      shareProductRecipientName,
      shareProductRecipientEmail,
      shareProductMessage,
      shareProductShowMessage,
      shareProductErrorMessage
    } = this.state;

    if (!showShareProductModal) {
      return null;
    }

    return (
      <div className="modal modal-hover modal-dialog modal-dialog-centered modal-product-image-cart" role="document">
        <div id="shareProductView" className="modal-content modal-product-image__wrapper cart-img">
          <div>
            <div className="modal-product-image-cart__header">
              <h1>{t('shareproduct.share')}</h1>
              <div style={{ cursor: "pointer" }} onClick={() => this.toggleShowShareProductModal()}>
                <FontAwesomeIcon icon={faTimes} size="1x" />
              </div>
            </div>

            <div className="modal-share-product-alert" hidden={shareProductErrorMessage === null}>
              <span className="modal-share-product-closebtn" onClick={() => this.clearShareProductErrorMessage()}>&times;</span>
              <strong>{t('error.errortitle')}</strong> {shareProductErrorMessage}
            </div>

            <div className="modal-share-product">
              <img src={product.productMedia[0].imageURL} alt="" />
              <h1>{product.productName}</h1>
              <h2 dangerouslySetInnerHTML={{
                __html: product.productSummary
              }} />

              <div className="modal-share-product__fields">
                <div className="modal-share-product__inputgroup">
                  <p>Send To:</p>
                  <input id="recipientName" name="recipientName" placeholder="Recipient Name"
                    value={shareProductRecipientName}
                    onChange={this.onRecipientNameChange}></input>
                </div>
                <div className="modal-share-product__inputgroup">
                  <p>Email:</p>
                  <input id="recipientEmail" name="recipientEmail" placeholder="Recipient Email"
                    value={shareProductRecipientEmail}
                    onChange={this.onRecipientEmailChange}></input>
                </div>
                {shareProductShowMessage
                  ? <div className="modal-share-product__textarea">
                    <textarea placeholder="Type in personalized message here:" rows="3"
                      value={shareProductMessage}
                      onChange={this.onShareProductMessageChange} />
                    <a onClick={this.toggleShareProductShowMessage} href="#">Hide Personal Message</a>
                  </div>
                  : <div className="modal-share-product__textarea">
                    <a onClick={this.toggleShareProductShowMessage} href="#"><FontAwesomeIcon className="far" icon={faChevronRight} size="1x" /> Add Personal Message </a>
                  </div>}
              </div>

              <div className="modal-share-product__buttons">
                <button onClick={() => this.shareProductInEmail()} className="btn btn-primary"
                  disabled={(shareProductRecipientName === '' || shareProductRecipientEmail === '')} >
                  {t('shareproduct.send')}
                </button>
                <button onClick={() => this.togglePreviewShareProductEmail()} className="btn btn-primary"
                  disabled={(shareProductRecipientName === '' || shareProductRecipientEmail === '')} >
                  {t('shareproduct.preview')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  AddedToCartModal = ({ t, product, selectedQty, showProceedToCheckout, productType, onAddToCartClickHandler, onProceedToCheckoutClickHandler, onProceedToShopHomeHandler }) => {

    if (!showProceedToCheckout) {
      return null;
    }

    if (productType !== 'Physical') {
      return (
        <div className="modal modal-hover modal-dialog modal-dialog-centered modal-product-image" role="document">
          <div className="modal-content modal-product-image__wrapper cart-img">
            <div className="modal-product-image__header">
              <h1>{t('cartmodal.addtocart')}</h1>
              <div style={{ cursor: "pointer" }} onClick={() => onAddToCartClickHandler()}>
                <FontAwesomeIcon icon={faTimes} size="1x" />
              </div>
            </div>
            <img src={product.bannerImage} alt="" />
            <div className="modal-product-image__buttons">
              <button onClick={() => onProceedToShopHomeHandler()} className="btn btn-light">
                {t('cartmodal.shophome')}
              </button>
              <button onClick={() => onProceedToCheckoutClickHandler()} className="btn btn-primary" style={{ color: "white" }}>
                {t('cartmodal.proceedtocheckout')}
              </button>
            </div>
          </div>
        </div>

      );
    }
    // non digital items
    return (
      <div className="modal modal-hover modal-dialog modal-dialog-centered modal-product-image-cart" role="document">
        <div className="modal-content modal-product-image-cart__wrapper cart-img">
          <div className="modal-product-image-cart__header">
            <h1>{t('cartmodal.addtocart')}</h1>
            <div style={{ cursor: "pointer" }} onClick={() => onAddToCartClickHandler()}>
              <FontAwesomeIcon icon={faTimes} size="1x" />
            </div>
          </div>
          <div className="modal-product-image-cart__right-content">
            <img src={product.productMedia[0].imageURL} alt="" />

            <div className="modal-product-image-cart__buttons">
              <div className="info">
                <h1>{product.productName}</h1>
                <p>Qty: {selectedQty}</p>
              </div>
              <div className="buttons-cont">
                <button onClick={() => onProceedToShopHomeHandler()} className="btn btn-light">
                  {t('cartmodal.shophome')}
                </button>
                <button onClick={() => onProceedToCheckoutClickHandler()} className="btn btn-primary" style={{ color: "white" }}>
                  {t('cartmodal.proceedtocheckout')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  };

  VideoPreviewModal = ({ t, width, mediaURL, onClickHandler }) => {

    if (mediaURL.mediaURL) {
      if (mediaURL.mediaURL.endsWith(".mp4")) {
        return (
          <video height="350">
            <source src={mediaURL.mediaURL} type="video/mp4" />
          </video>
        );
      }

      return <div className="modal fade"
        id="productImageModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-product-image"
          role="document"
        >
          <div
            style={{
              height: width > 1000 ? 450 : width > 500 ? 400 : 250,
              width: width > 1000 ? 630 : width > 500 ? 600 : '100%'
            }}
            className="modal-content modal-product-image__wrapper">
            <div className="modal-product-image__header">
              <h1>{t('description.videopreview')}</h1>
              <div style={{ cursor: "pointer" }} onClick={() => onClickHandler()} data-dismiss="modal">
                <FontAwesomeIcon icon={faTimes} size="1x" />
              </div>
            </div>
            <iframe
              title="Video Preview"
              id="iframeId"
              height={width > 1000 ? 480 : width > 500 ? 350 : 300}
              width={width > 1000 ? 590 : width > 500 ? 460 : '100%'}
              src={`https://player.vimeo.com/video/${mediaURL.mediaURL}`}></iframe>
          </div>
        </div>
      </div>;
    }

    // return image
    return <div className="modal fade"
      id="productImageModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-product-image" role="document">
        <div className="modal-content modal-product-image__wrapper">
          <div className="modal-product-image__header">
            <h1>{t('description.preview')}</h1>
            <div style={{ cursor: "pointer" }} data-dismiss="modal">
              <FontAwesomeIcon icon={faTimes} size="1x" />
            </div>
          </div>
          <img src={mediaURL.imageURL} alt="" />
        </div>
      </div>
    </div>;
  };

  onPhysicalAndDigitalDropdownChange = (e) => {
    if (e && e.value) {
      this.setState({ physicalAndDigitalDropdownValue: e.value });
    }
  };

  ProductDetails = ({ t, productName, bannerImage, productPrice, currency, productTC, productSKU, productType, productPV, productSummary, productMedia, options }) => {
    return (
      <Row className="product-description__header">
        <Col>

          <div className="product-description__title-and-sharebutton">
            <h1 className="product-description__title-and-sharebutton__title animated fadeIn">{productName}</h1>
            {
              this.props.isGuest ?
                ''
                : (
                  <div className="button-container">
                    <button onClick={this.shareButtonClickHandler} className="btn btn-primary btn-lg">
                      {this.props.isGeneratingCustomerEnrollWithProductLink ? <FontAwesomeIcon icon={faSync} size="1x" spin /> : t('shareproduct.share')}
                    </button>
                  </div>
                )
            }
          </div>

          <div
            className="product-description__image animated fadeIn"
          >
            <img src={bannerImage} alt="Product Banner" />
          </div>
          <div className="product-description__detail-container animated fadeIn">
            <div className="product-description__left">
              <p>{t('description.price')}: <span className="product-description__product-price">
                ${productPrice.toFixed(2)}
                <span style={{ fontSize: 16, color: '#fff', fontWeight: 400, paddingLeft: 4 }}>({currency})</span>
              </span>
              </p>
              <p>{t('description.tripcredits')}: <span className="product-description__product-tc">{(!productTC || productTC === 0) ? productTC : productTC.toFixed(2)}</span></p>
              <p className="product-description__product-pv"> {t('description.sku')}: <span style={{ fontWeight: 600 }}>{productSKU}</span></p>
              {
                productType !== 'marketplace' && productType !== null ? (
                  <>
                    <p className="product-description__product-pv"> {t('description.format')}: <span style={{ fontWeight: 600 }}>{this.isProductDigital()}</span></p>
                   </>
                ) : ('')
              }
              <p className="product-description__product-pv"> {t('description.pv')}: <span style={{ fontWeight: 600 }}>{productPV.toFixed(2)}</span></p>
              <div className="product-description__product-qty-cont">
                <p className="product-description__product-qty"> Qty:</p>
                {
                  this.isProductDigital() === 'Physical' ? (
                    <input
                      style={{ width: "70px" }}
                      className="form-control"
                      type="text" id="quantity"
                      pattern="[0-9]*" inputmode="decimal"
                      maxLength="2" minLength="1"
                      value={this.state.selectedQty}
                      onChange={this.onQuantityChange}
                      name="quantity" min="1" max="20" />

                  ) : (
                    1
                  )
                }
              </div>
              <div className="button-container">
                <p
                  onClick={() => this.onAddToCart()}
                  className="btn btn-primary btn-lg">{this.state.isAddingItemToCart ? <FontAwesomeIcon icon={faSync} size="1x" spin /> : t('description.addtocart')}
                </p>{this.state.physicalAndDigitalDropdownEnabled &&
                  <div className="cart-dropdown">
                    <Dropdown
                      options={['Physical', 'Digital', 'Both']}
                      onChange={this.onPhysicalAndDigitalDropdownChange}
                      value={this.state.physicalAndDigitalDropdownValue} />
                  </div>}
              </div>
            </div>
            <div className="description-container">
              <p style={{ color: '#fff' }} dangerouslySetInnerHTML={{
                __html: productSummary
              }}
              />
              {
                productMedia.length >= 1 ? (
                  <OwlCarousel
                    options={{ ...options, stagePadding: this.state.width > 1000 ? 80 : this.state.width > 600 ? 60 : this.state.width > 500 ? 40 : this.state.width > 100 ? 16 : 20 }}>
                    {productMedia.map((image, i) => {
                      return (
                        <div key={i} className="product-description__img" onClick={() => this.onModalImageClick(image)}>
                          {image.mediaURL ?
                            <FontAwesomeIcon data-toggle="modal"
                              data-target="#productImageModal" id='play-icon' icon={faPlayCircle} size="5x" />
                            : ''
                          }
                          <img
                            data-toggle="modal"
                            data-target="#productImageModal"
                            src={image.imageURL}
                            alt=""
                          />
                        </div>
                      );
                    })}
                  </OwlCarousel>
                ) : ('')}
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  ProductDescription = ({ t, productDescription }) => {
    return (
      <div className="product-description__summary">
        {
          !!productDescription.length
            ? (
              <>
                <h3 className="product-description__title">{t('description.productdescription')}</h3>
                <p style={{ color: '#fff' }} dangerouslySetInnerHTML={{
                  __html: this.state.width < 500 ? this.truncateText(productDescription) : productDescription
                }} />
              </>
            ) : ('')
        }
        {
          !!productDescription.length &&
            productDescription.length > 700 ? (
            this.state.showFullText ? (
              <p id="show-more-link" onClick={() => this.setState({ showFullText: false })}>
                {this.state.width < 500 ? 'Show less' : ''}
              </p>
            ) : (
              <p id="show-more-link" onClick={() => {
                this.setState({ showFullText: true });
              }}>{this.state.width < 500 ? 'Show more' : ''}</p>
            )
          ) : ('')
        }
      </div>
    );
  };

  SpeakersCarousel = ({ speakers, onClickHandler, options }) => {

    if (speakers === null || speakers === undefined) return ('');

    return (
      <>
        <h3 className="product-description__title">Speakers</h3>
        <OwlCarousel
          options={options}
        >
          {speakers.map((speaker) => {
            return (
              <div key={speaker.speaker}>
                <img
                  id="additional-img"
                  onClick={() => onClickHandler(speaker.speaker)}
                  alt="product speaker"
                  data-target="#speakerModal"
                  data-toggle="modal"
                  className="product-description__items-img"
                  src={speaker.imageURL}
                />
                <p>{speaker.speaker}</p>
              </div>
            );
          })}
        </OwlCarousel>
      </>
    );
  };

  AdditionalProductsCarousel = ({ t, parentCategory, additionalProducts, options }) => {

    if (parentCategory === 'subscription' || additionalProducts.length < 1) return ('');

    return (
      <>
        <h3 className="product-description__title">{t('description.additionalproducts')}</h3>
        <OwlCarousel
          options={options}
        >
          {
            additionalProducts.map((product => {
              return (
                <div className="product-description__additional-img" key={product.productSKU}>
                  <img
                    alt="additional products"
                    id="additional-img"
                    onClick={() => this.props.onAdditionalProductHandler(product)}
                    key={product.imageURL}
                    className="carousel-img"
                    src={product.imageURL}
                  />
                  <p className="line-clamp">
                    {product.productName}
                  </p>
                </ div>
              );
            }))
          }
        </OwlCarousel>
      </>
    );
  };

  render() {
    const {
      t,
      product: {
        productName,
        productPrice,
        productPV,
        productType,
        productTC,
        productDescription,
        productSummary,
        productMedia,
        additionalProducts,
        bannerImage,
        productDetails,
        productSKU,
        speakers
      },
      currency,
      parentCategory
    } = this.props;
    const { width, speakerName, speakerAudio, mediaURL, selectedQty, showProceedToCheckout } = this.state;

    if (this.props.product === null) return ('');

    // Owl carousel options
    const options = {
      items: 4,
      nav: false,
      rewind: true,
      autoplay: false,
      margin: 15,
      dots: false,
      responsive: {
        0: {
          items: 3,
        },
        600: {
          items: 3,
        },
        1000: {
          items: 4,
        },
      },
    };

    return (
      <>
        <this.ProductDetails
          t={t}
          productName={productName}
          bannerImage={bannerImage}
          productPrice={productPrice}
          currency={currency}
          productTC={productTC}
          productSKU={productSKU}
          productType={productType}
          productPV={productPV}
          productSummary={productSummary}
          productMedia={productMedia}
          options={options}
        />

        <this.ProductDescription t={t} productDescription={productDescription} />

        {
          productDetails.length >= 1 ? (
            <>
              <h3 className="product-description__title">Product Content</h3>
              {productDetails.length >= 1 ? (
                <MediaList productMedia={productDetails} />
              ) : ('')}
            </>
          ) : ('')
        }

        <this.SpeakersCarousel speakers={speakers} onClickHandler={this.onSpeakerModalClick} options={options} />

        <this.AdditionalProductsCarousel t={t} parentCategory={parentCategory} additionalProducts={additionalProducts} options={options} />

        <Speaker
          onAdditionalProductHandler={this.props.onAdditionalProductHandler}
          speakerName={speakerName}
          speakerAudio={speakerAudio} />

        <this.AddedToCartModal
          t={t}
          product={this.props.product}
          selectedQty={selectedQty}
          showProceedToCheckout={showProceedToCheckout}
          productType={this.isProductDigital()}
          onProceedToShopHomeHandler={this.proceedToShopHome}
          onAddToCartClickHandler={this.toggleShowProceedToCheckout}
          onProceedToCheckoutClickHandler={this.onCheckoutClick}
        />
        {this.state.showShareProductModal && <this.ShareProductModal t={t} product={this.props.product} />}
        {this.state.previewShareProductEmail && <this.PreviewShareProductModal t={t} product={this.props.product} />}
        <this.VideoPreviewModal t={t} width={width} mediaURL={mediaURL} onClickHandler={this.onModalClose} />
        {this.state.errorMessage && <ErrorMessageModal t={t} message={this.state.errorMessage} onClickHandler={this.clearErrorMessage} />}
        {this.state.successMessage && <SuccessMessageModal t={t} message={this.state.successMessage} onClickHandler={this.clearSuccessMessage} />}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    productDetails: state.productDetailReducer.productDetails,
    speakerAudio: state.productDetailReducer.speakerAudio,
    langApi: state.userReducer.langApiParam,
    currency: state.userReducer.currency,
    isGeneratingCustomerEnrollWithProductLink: state.userReducer.isGeneratingCustomerEnrollWithProductLink
  };
};

export default connect(mapStateToProps, {
  getSpeakerAudio,
  addItemToCart,
  sendProductEmail,
  getCustomerEnrollWithProductLink
})
  (withRouter(withTranslation()(ProductDetailDescription)));
