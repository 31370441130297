import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import Col from 'react-bootstrap/Col';


class HomeListContainerItem extends Component {

  onMainCategoryClick = (category) => {
    if (category.parentCategoryID === null) {
      window.location.href = 'https://www.costore.com/life?oib=1';
      return
    }
    this.props.history.push(`/category/${category.parentCategoryID}`)
  }

  render() {
    const { category, t } = this.props;
    if (!category.hidden) {
      return (
        <Col
          id={category.categoryName}
          md={4}
          xs={6}
          key={category.parentCategoryName}
        >
          <div onClick={() => this.onMainCategoryClick(category)}
            className={`main-content__container-item ${category.parentCategoryName}`}
          >
            <h3>{t(`categories.${category.parentCategoryName}`)}</h3>
          </div>
        </Col>
      )
    }
    return ''
  }
}

export default withRouter(
  withTranslation()
    (HomeListContainerItem)
);
