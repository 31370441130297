import React, { Component } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import { connect } from 'react-redux';
import { getProductsByChildCategoryId } from '../../store/actions';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import ProductList from '../ProductList/ProductList';
import Pagination from "react-js-pagination";
import LoadingIndicator from '../../components/LoadingIndicator';
import { getCookie, deleteCookie } from '../../utils/cookieUtil';
import { findChildCategoryNode } from '../../utils/utils';

class SubCategoryList extends Component {
  state = {
    childItems: [],
    activePage: 1,
    pageNumbers: [],
    numberOfPages: 0,
    parentCategory: '',
    childCategory: '',
    showFooter: false
  };

  async componentDidMount() {
    if (this.props.baselineLoad) {
      this.setUpProductListItems();
    }
  }

  componentDidUpdate = async (prevProps) => {
    if (prevProps.baselineLoad !== this.props.baselineLoad) {
      this.setUpProductListItems();
    }
    if (prevProps.marketAndCustomerLevelID !== this.props.marketAndCustomerLevelID ||
      prevProps.langApi !== this.props.langApi) {
      this.setUpProductListItems();
    }
  };

  setUpProductListItems = () => {
    const categoryId = parseInt(this.props.match.params.categoryId, 10);
    const subCategoryID = parseInt(this.props.match.params.subCategoryID, 10);
    const parentCategory = this.props.baselineLoad[1].parentCategoryIds.filter((category) => {
      return category.parentCategoryID === categoryId;
    });

    const childCategoryNode = findChildCategoryNode(subCategoryID, parentCategory[0]);
    // const childCategory = parentCategory[0].childCategories.filter((childCategory) => {
    //   return childCategory.childCategoryID === subCategoryID;
    // });

    let customerLevelID = null;
    let marketID = null;

    if (this.props.isGuest) {
      const country = JSON.parse(getCookie('guest_country'));

      if (!country.customerLevelID || !country.marketID) {
        deleteCookie("access_token");
        deleteCookie("cart_guid");
        deleteCookie("selected_lang");
        deleteCookie("guest_country");
        deleteCookie("guest_region");
        deleteCookie("is_guest");
        deleteCookie("is_quick_buy");
        this.props.history.push('/login');
      }

      customerLevelID = country.customerLevelID;
      marketID = country.marketID;
    }

    this.props.getProductsByChildCategoryId(subCategoryID, this.props.accessToken, this.props.langApi, 1, customerLevelID, marketID, ((response) => {
      this.setState({
        childItems: response[0],
        childCategory: !!childCategoryNode.childCategoryName ? childCategoryNode.childCategoryName : childCategoryNode.subCategoryName,
        parentCategory: parentCategory[0].parentCategoryName,
        numberOfPages: response[1][0].numberOfPages,
        showFooter: true
      });
      this.generatePageNumber();
    }).bind(this));
  };

  generatePageNumber = () => {
    const pageNumArray = [];
    for (let i = 0; i < this.state.numberOfPages; i++) {
      pageNumArray.push(i + 1);
    }
    this.setState({ pageNumbers: pageNumArray });
  };

  handlePageChange(pageNumber) {
    const childCategoryId = this.props.match.params.subCategoryID;

    let customerLevelID = null;
    let marketID = null;

    if (this.props.isGuest) {
      const country = JSON.parse(getCookie('guest_country'));

      if (!country.customerLevelID || !country.marketID) {
        deleteCookie("access_token");
        deleteCookie("cart_guid");
        deleteCookie("selected_lang");
        deleteCookie("guest_country");
        deleteCookie("guest_region");
        deleteCookie("is_guest");
        deleteCookie("is_quick_buy");
        this.props.history.push('/login');
      }

      customerLevelID = country.customerLevelID;
      marketID = country.marketID;
    }

    this.props.getProductsByChildCategoryId(childCategoryId, this.props.accessToken, this.props.langApi, pageNumber, customerLevelID, marketID, ((response) => {
      this.setState({ childItems: response[0] });
      this.setState({ activePage: pageNumber });
      window.scrollTo(0, 0);
    }).bind(this));
  }

  showHeader = () => {
    const { hideNav } = this.props;
    if (!!hideNav)
      return false;
    else
      return true;
  };

  render() {
    return (
      <>
        {
          this.showHeader()
            ? (<Header childCategory={this.state.childCategory} parentCategory={this.state.parentCategory} subCategoryName="" />)
            : (<div className="quick-buy-spacer"></div>)
        }
        {
          this.props.isLoadingProductChildCategoryItems ?
            (<LoadingIndicator />) : (
              <>

                <Container>
                  <Row>
                    <ProductList
                      category={'category-view-img'}
                      products={this.state.childItems}
                    />
                  </Row>
                  <Row className="category-view__pagination-container">
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={4}
                      totalItemsCount={this.state.pageNumbers.length}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange.bind(this)}
                    />
                  </Row>
                </Container>
                {this.state.showFooter && <Footer />}
              </>)
        }
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    marketAndCustomerLevelID: state.userReducer.marketAndCustomerIdParam,
    langApi: state.userReducer.langApiParam,
    baselineLoad: state.productCategoryReducer.baselineLoad,
    isLoadingProductChildCategoryItems: state.productCategoryReducer.isLoadingProductChildCategoryItems
  };
};

export default connect(mapStateToProps, {
  getProductsByChildCategoryId
})(SubCategoryList);
