import React from 'react'
import OwlCarousel from 'react-owl-carousel2';
import { Link } from 'react-router-dom';

const CustomCarousel = ({ products, parentCategoryID }) => {

  if (!products) return ('')

  const options = {
    items: 4,
    loop: false,
    stagePadding: 25,
    center: false,
    rewind: true,
    autoplay: false,
    margin: 15,
    responsive: {
      0: {
        items: 3,
      },
      350: {
        items: 3,
      },
      750: {
        items: 4,
      },
    },
  };

  return (
    <OwlCarousel options={options}>
      {
        products.map((product, i) => {
          let id = parentCategoryID;
          if (!id) {
            id = product.parentCategoryID
          }
          let productId = product.productCategoryID
          return (
            <div id="category-view-owl" key={id + product.productSKU + productId}>
              <Link className="category-view-img__link" to={`${id}/product-detail/${product.productSKU}/${productId}`}>
                <div style={{ backgroundImage: `url(${product.productImage})`, backgroundSize: "cover", backgroundPosition: "center" }} className="item"></div>
                <p className="category-view-img__title line-clamp">{ product.productName }</p>
              </Link>
            </div>
          )
        })
      }
    </OwlCarousel>
  )
}

export default CustomCarousel;