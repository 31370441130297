import React from 'react'
import {Link} from 'react-router-dom'

export default function footer() {
  return (
    <div className="footer">
      <div className="footer__logo">
        <Link to={'/'}>
        <img
          className="footer__logo_"
          src="https://main.secure.footprint.net/public/icon/life.png"
          alt=""
        />
        </Link>
            <p>Copyright 2020</p>
      </div>

      <div className="footer__container">
        <p>Terms of Use</p>
        <p>US</p>
        <p>Canada</p>
        <p>Europe</p>
      </div>
    </div>
  )
}
