import * as actionTypes from './actionTypes'
import options from '../../config'
import { apiAction } from './api';

export const getProductDetailBySku = (sku, productCategoryID, access_token, lang, customerLevelID, marketID, onSuccess = (res) => {}, onFailure = (e) => {}, onFinally = () => {}) => apiAction({
  url: customerLevelID ? `${options.APIURL}/api/shoppingcart/${sku}/${productCategoryID}/${lang}?guestCustomerLevelID=${customerLevelID}&guestMarketID=${marketID}` : `${options.APIURL}/api/shoppingcart/${sku}/${productCategoryID}/${lang}`,
  onSuccess: (response) => {
    onSuccess(response.productDetails[0]);
    return {
      type: actionTypes.GET_PRODUCT_DETAIL_BY_SKU,
      payload: response.productDetails[0]
    };
  },
  onFailure: (error) => {
    if (onFailure) {
      onFailure(error);
    }
    console.log("getProductDetailBySku api error");
  },
  onFinally: () => {
    onFinally();
  },
  label: actionTypes.GET_PRODUCT_DETAIL_BY_SKU,
  accessToken: access_token
});

export const getSpeakerAudio = (speaker, access_token, lang, customerLevelID, marketID, onSuccess = (res) => {}, onFailure = (e) => {}, onFinally = () => {}) => apiAction({
  url: customerLevelID ? `${options.APIURL}/api/shoppingcart/search/speaker/${encodeURIComponent(speaker)}/${lang}?guestCustomerLevelID=${customerLevelID}&guestMarketID=${marketID}` : `${options.APIURL}/api/shoppingcart/search/speaker/${encodeURIComponent(speaker)}/${lang}`,
  onSuccess: (response) => {
    onSuccess(response);
    return {
      type: actionTypes.GET_SPEAKER_AUDIO,
      payload: response
    };
  },
  onFailure: (error) => {
    if (onFailure) {
      onFailure(error);
    }
    console.log("getSpeakerAudio api error");
  },
  onFinally: () => {
    onFinally();
  },
  accessToken: access_token
});

export const sendProductEmail = (access_token, data, onSuccess = (res) => {}, onFailure = (e) => {}, onFinally = () => {}) => apiAction({
  url: `${options.APIURL}/api/enroll/guest/email`,
  method: "POST",
  data: data,
  onSuccess: (response) => {
    onSuccess(response);
    return {
      type: actionTypes.SEND_PRODUCT_EMAIL,
      payload: response
    };
  },
  onFailure: (error) => {
    if (onFailure) {
      onFailure(error);
    }
    console.log("sendProductEmail api error");
  },
  onFinally: () => {
    onFinally();
  },
  accessToken: access_token
});

export const getCustomerEnrollWithProductLink = (access_token, productID, lang, onSuccess = (res) => {}, onFailure = (e) => {}) => apiAction({
  url: `${options.APIURL}/api/enroll/guest`,
  method: "POST",
  data: {
    productID
  }, 
  onSuccess: (response) => {
    onSuccess(response);
    return {
      type: actionTypes.GET_CUSTOMER_ENROLL_WITH_PRODUCT_LINK,
      payload: response
    };
  },
  onFailure: (error) => {
    onFailure(error);
    console.log("getCustomerEnrollWithProductLink api error");
  },
  label: actionTypes.GET_CUSTOMER_ENROLL_WITH_PRODUCT_LINK,
  accessToken: access_token
});