import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingBag } from '@fortawesome/pro-light-svg-icons';

class BreadCrumb extends Component {
  state = {
    parentCategory: '',
    childCategory: '',
    parentCategoryIds: [],
    childCategories: [],
    parentCategoryID: ''
  }

  onBreadCrumbItemClick = () => {
    const categoryID = this.props.match.params.categoryId;
    this.props.history.push({
      pathname: `/category/${categoryID}`,
      childCategories: this.state.childCategories
    });
  }
  
  render() {
    const { t } = this.props;
    return (
      <ul className="breadcrumbs header__bread-crumb">
        <li className="nav-home">
          <NavLink to="/">
            <FontAwesomeIcon data-dismiss="modal" icon={faShoppingBag} size="1x" />
          </NavLink>
        </li>
        <li className="separator">
          <i className="flaticon-right-arrow"></i>
        </li>
        <p onClick={() => this.onBreadCrumbItemClick()} className="nav-item header__bread-crumb__title">
          {t(`categories.${this.props.parentCategoryName}`)}
        </p>
        {
          this.props.childCategory ? (
            <>
              <li className="separator">
                <i className="flaticon-right-arrow"></i>
              </li>
              <li className="nav-item">
                <p className="header__bread-crumb__title" href="#">{t(`subcategories.${this.props.childCategory}`)}</p>
              </li>
            </>
          ) : (
              ''
            )
        }
      </ul>

      
    )
  }
}

export default withRouter(withTranslation()((BreadCrumb)))



