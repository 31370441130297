import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'

import ProductListItem from './ProductListItem';

class ProductList extends Component {

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  onProductClick = (item) => {
    const { history } = this.props;

    let parentCategoryID = this.props.match.params.categoryId;
    if (!parentCategoryID) {
      parentCategoryID = item.parentCategoryID
    }
    // change routeID if promotional or featured items
    if (parentCategoryID === '793' || parentCategoryID === '792') {
      history.push(`/category/${parentCategoryID}/product-detail/${item.productSKU}/${parentCategoryID}`)
      return 
    }

    history.push(`/category/${parentCategoryID}/product-detail/${item.productSKU}/${item.productCategoryID}`)
  }

  render() {
    const { products, category } = this.props;

    if (!products) return ('')

    return products.map((item, index) => {
      return (
        <ProductListItem
          key={item.productID + item.productType + index}
          category={category}
          onProductClick={() => this.onProductClick(item)}
          item={item}
        />
      )
    })
  }
}

export default withRouter(ProductList)