import options from '../../config'
import * as actionTypes from './actionTypes';
import { apiAction } from './api';

export const setCountryIndex = (selectedCountryIndex) => {
  return {
    type: actionTypes.SET_COUNTRY_INDEX,
    payload: selectedCountryIndex
  }
}

export const setLangApiParam = (langApiParam) => {
  return {
    type: actionTypes.SET_LANG_API_PARAM,
    payload: langApiParam
  }
}

export const setCustomerAndMarketIDParam = (customerAndMarketIdParam) => {
  return {
    type: actionTypes.SET_CUSTOMER_AND_MARKET_ID_PARAM,
    payload: customerAndMarketIdParam
  }
}

export const selectedCountry = (country) => {
  return {
    type: actionTypes.SELECTED_COUNTRY,
    payload: country
  }
}

export const setCurrency = (currency) => {
  return {
    type: actionTypes.SET_CURRENCY,
    payload: currency
  }
}

export const setNumberCartItems = (numberCartItems) => {
  return {
    type: actionTypes.NUMBER_CART_ITEMS,
    payload: numberCartItems
  }
}

export const loginUser = (credentials, onSuccess = (res) => {}, onFailure = (e) => {}, onFinally = () => {}) => apiAction({
  url: `${options.APIURL}/signin`,
  method: "POST",
  data: credentials,
  onSuccess: (response) => {
    onSuccess(response);
    return {
      type: actionTypes.LOGIN_USER,
      payload: response
    };
  },
  onFailure: (error) => {
    if (onFailure) {
      onFailure(error);
    }
    console.log("loginUser api error");
  },
  onFinally: () => {
    onFinally();
  }
});


export const addItemToCart = (productID, access_token, cartID, lang, qty, onSuccess = (res) => {}, onFailure = (e) => {}, onFinally = () => {}) => apiAction({
    url: `${options.APIURL}/api/shoppingcart/cart/items`,
    method: "PUT",
    data: {
      productID,
      cartID,
      lang,
      qty
    },
    onSuccess: (response) => {
      onSuccess(response);
      return {
        type: actionTypes.ADD_ITEM_TO_CART,
        payload: response
      };
    },
    onFailure: (error) => {
      if (onFailure) {
        onFailure(error);
      }
      console.log("addItemToCart api error");
    },
    onFinally: () => {
      onFinally();
    },
    accessToken: access_token
});

export const getUserCart = (access_token, cartGuid, customerLevelID, onSuccess = (res) => {}, onFailure = (e) => {}, onFinally = () => {}) => apiAction({
  url: `${options.APIURL}/api/shoppingcart/cart/items`,
  method: "POST",
  data: {
    cartID: cartGuid,
    customerLevelID: customerLevelID,
    lang: "en"
  },
  onSuccess: (response) => {
    onSuccess(response);
    return {
      type: actionTypes.GET_USER_CART,
      payload: response
    };
  },
  onFailure: (error) => {
    if (onFailure) {
      onFailure(error);
    }
    console.log("getUserCart api error");
  },
  onFinally: () => {
    onFinally();
  },
  accessToken: access_token
});

export const createCartGuid = (access_token, onSuccess = (res) => {}, onFailure = (e) => {}, onFinally = () => {}) => apiAction({
  url: `${options.APIURL}/api/shoppingcart/cart`,
  onSuccess: (response) => {
    onSuccess(response);
    return {
      type: actionTypes.GET_CART_GUID,
      payload: response
    };
  },
  onFailure: (error) => {
    if (onFailure) {
      onFailure(error);
    }
    console.log("createCartGuid api error");
  },
  onFinally: () => {
    onFinally();
  },
  accessToken: access_token
});

export const verifyToken = (access_token, onSuccess = (res) => {}, onFailure = (e) => {}, onFinally = () => {}) => apiAction({
  url: `${options.APIURL}/verify`,
  method: "POST",
  onSuccess: (response) => {
    onSuccess(response);
    return {
      type: actionTypes.VERIFY_TOKEN,
      payload: true
    };
  },
  onFailure: (error) => {
    if (onFailure) {
      onFailure(error);
    }
    console.log("verify token api error");
  },
  onFinally: () => {
    onFinally();
  },
  accessToken: access_token
});

export const getUserDetails = (access_token, onSuccess = (res) => {}, onFailure = (e) => {}, onFinally = () => {}) => apiAction({
  url: `${options.APIURL}/api/shoppingcart/userdetails`,
  onSuccess: (response) => {
    onSuccess(response);
    return {
      type: actionTypes.GET_USER_DETAILS,
      payload: response
    };
  },
  onFailure: (error) => {
    if (onFailure) {
      onFailure(error);
    }
    console.log("getUserDetails api error");
  },
  onFinally: () => {
    onFinally();
  },
  accessToken: access_token
});

export const getCartTotals = (access_token, cartID, customerLevelID, country, region = "", onSuccess = (res) => {}, onFailure = (e) => {}, onFinally = () => {}) => apiAction({
  url: `${options.APIURL}/api/shoppingcart/cart/total`,
  method: "POST",
  data: {
    cartID,
    customerLevelID,
    country,
    region
  },
  onSuccess: (response) => {
    onSuccess(response);
    return {
      type: actionTypes.GET_CART_TOTALS,
      payload: response
    };
  },
  onFailure: (error) => {
    if (onFailure) {
      onFailure(error);
    }
    console.log("getCartTotals api error");
  },
  onFinally: () => {
    onFinally();
  },
  accessToken: access_token
});

export const deleteCartItem = (productID, access_token, cartID, lang, onSuccess = (res) => {}, onFailure = (e) => {}, onFinally = () => {}) => apiAction({
  url: `${options.APIURL}/api/shoppingcart/cart/items`,
  method: "DELETE",
  data: {
    productID,
    cartID,
    lang
  },
  onSuccess: (response) => {
    onSuccess(response);
    return {
      type: actionTypes.DELETE_CART_ITEM,
      payload: response
    };
  },
  onFailure: (error) => {
    if (onFailure) {
      onFailure(error);
    }
    console.log("deleteCartItem api error");
  },
  onFinally: () => {
    onFinally();
  },
  accessToken: access_token
});

export const updateCartItem = (productID, access_token, cartID, lang, qty, onSuccess = (res) => {}, onFailure = (e) => {}, onFinally = () => {}) => apiAction({
  url: `${options.APIURL}/api/shoppingcart/cart/items`,
  method: "PATCH",
  data: {
    productID,
    cartID,
    lang,
    qty
  },
  onSuccess: (response) => {
    onSuccess(response);
    return {
      type: actionTypes.UPDATE_CART_ITEM,
      payload: response
    };
  },
  onFailure: (error) => {
    if (onFailure) {
      onFailure(error);
    }
    console.log("updateCartItem api error");
  },
  onFinally: () => {
    onFinally();
  },
  accessToken: access_token
});

export const verifyGuestLogin = (access_token, onSuccess = (res) => {}, onFailure = (e) => {}, onFinally = () => {}) => apiAction({
  url: `${options.APIURL}/verify/guest`,
  method: "POST",
  data: {},
  onSuccess: (response) => {
    onSuccess(response);
    return {
      type: actionTypes.VERIFY_GUEST_TOKEN,
      payload: response
    };
  },
  onFailure: (error) => {
    if (onFailure) {
      onFailure(error);
    }
    console.log("verifyGuestLogin api error");
  },
  onFinally: () => {
    onFinally();
  },
  accessToken: access_token
});

export const getRegionsByCountry = (country, onSuccess = (res) => {}, onFailure = (e) => {}, onFinally = () => {}) => apiAction({
  url: `${options.APIURL}/public/util/regions/${country}`,
  onSuccess: (response) => {
    onSuccess(response);
    return {
      type: actionTypes.GET_REGIONS,
      payload: response
    };
  },
  onFailure: (error) => {
    if (onFailure) {
      onFailure(error);
    }
    console.log("getRegionsByCountry api error");
  },
  onFinally: () => {
    onFinally();
  }
});
