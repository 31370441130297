import React from 'react';
import Col from 'react-bootstrap/Col';

const ProductListItem = ({ item, onProductClick, category }) => {
  return (
    (
      <Col
        md={4}
        xs={6}
        className={`product-list__item ${category} animated fadeIn delay-.5s`}
        onClick={() => onProductClick(item)}
      >
        <img src={item.productImage} alt="product list" />
        <p className="product-list__title animated">{item.productName}</p>
      </Col>
    )
  )
}

export default ProductListItem;
