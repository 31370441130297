import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import '@brainhubeu/react-carousel/lib/style.css';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Header from '../Header';
import Footer from '../Footer';
import MainCategoryListContainer from '../MainCategoryListContainer/MainCategoryListContainer';
import ProductList from '../../containers/ProductList/ProductList';
import FeaturedProductsCarousel from '../../containers/Carousel/FeaturedProductsCarousel';
import PromotionalProductsCarousel from '../../containers/Carousel/PromotionalProductsCarousel';
import OwlCarousel from 'react-owl-carousel2';
import Row from 'react-bootstrap/Row';
import './owl.carousel.css';
import { getFeaturedProducts, getPromotionalProducts, getUserDetails } from '../../store/actions';
import { getCookie, deleteCookie } from '../../utils/cookieUtil';

class HomeMainContent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      promotionalProducts: [],
      featuredProducts: [],
      allParentCategoryIds: [],
      memberCategories: [],
      publicCategories: [],
      showFooter: false,
      homeIconDisplay: {
        marketplaceIconDisplay: {
          displayicon: false,
          link: "/category/790",
          imageURL: "https://main.secure.footprint.net/public/icon/marketplace_en.jpg"
        },
        zinzinoIconDisplay: {
          displayicon: false,
          link: "/category/790/sub-category/786",
          imageURL: "https://main.secure.footprint.net/public/icon/zinzino.png"
        },
        muscadineIconDisplay: {
          displayicon: false,
          link: "/category/790/sub-category/788",
          imageURL: "https://main.secure.footprint.net/public/icon/mighty_muscadine.jpg"
        },
        xebecIconDisplay: {
          displayicon: false,
          link: "/category/790/sub-category/782",
          imageURL: "https://main.secure.footprint.net/public/icon/xebec.png"
        },
        satoshiIconDisplay: {
          displayicon: false,
          link: "/category/790/sub-category/781",
          imageURL: "https://main.secure.footprint.net/public/icon/satoshi.png"
        },
        honeyBarsIconDisplay: {
          displayicon: false,
          link: "/category/790/sub-category/794",
          imageURL: "https://main.secure.footprint.net/public/icon/honeybar.jpg"
        },
        goldshieldIconDisplay: {
          displayicon: false,
          link: "/category/790/sub-category/795",
          imageURL: "https://main.secure.footprint.net/public/icon/goldshield.png"
        },
        olivierIconDisplay: {
          displayicon: false,
          link: "/category/790/sub-category/812",
          imageURL: "https://main.secure.footprint.net/public/icon/olivier.jpg"
        },
        tgfIconDisplay: {
          displayicon: false,
          link: "/category/790/sub-category/814",
          imageURL: "https://main.secure.footprint.net/public/icon/v2/truly_good_foods.jpg"
        }
      }
    };
  }

  componentDidMount = async () => {
    if (this.props.baselineLoad) {
      this.setHomeItems();
    }
    if (this.props.isGuest) {
      const country = JSON.parse(getCookie('guest_country'));
      if (country && country.countryValue) {
        this.setMarketplaceStatus(country.countryValue);
      } else {
        this.setMarketplaceStatus('');
      }
      this.setState({ showFooter: true });
    } else {
      this.props.getUserDetails(this.props.accessToken, ((response) => {
        if (response && response[0] && response[0].Country) {
          this.setMarketplaceStatus(response[0].Country);
        } else {
          this.setMarketplaceStatus('');
        }

        this.setState({ showFooter: true });
      }).bind(this));
    }
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.baselineLoad !== this.props.baselineLoad) {
      this.setHomeItems();
    }
    if (prevProps.marketAndCustomerLevelID !== this.props.marketAndCustomerLevelID ||
      prevProps.langApi !== this.props.langApi) {
      if (this.props.isGuest) {
        const country = JSON.parse(getCookie('guest_country'));
        if (country && country.countryValue) {
          this.setMarketplaceStatus(country.countryValue);
        } else {
          this.setMarketplaceStatus('');
        }
        this.setState({ showFooter: true });
      } else {
        this.setMarketplaceStatus(this.props.userDetails[0].Country);
      }
    }

  };

  setMarketplaceStatus = (userCountry) => {
    var marketplaceCountries = ["US", "CA", "BS", "DO", "HT", "AU", "FR", "MX", "PE"];
    var muscadineCountries = ["US", "CA", "BS", "DO", "HT", "MX"];
    var zinzinoCountries = ["US", "CA", "BS", "DO", "HT", "AU", "FR"];
    var snaxXebecCountries = ["US", "CA", "BS", "DO", "HT"];
    var satoshiCountries = ["US", "BS", "DO", "HT"];
    var bShowMarketplace = (marketplaceCountries.indexOf(userCountry) > -1);
    var bShowZinzino = (zinzinoCountries.indexOf(userCountry) > -1);
    var bShowMuscadine = (muscadineCountries.indexOf(userCountry) > -1);
    var bShowSnax = (snaxXebecCountries.indexOf(userCountry) > -1);
    var bShowXebec = (snaxXebecCountries.indexOf(userCountry) > -1);
    var bShowSatoshi = (satoshiCountries.indexOf(userCountry) > -1);
    var bShowHoneyBars = (snaxXebecCountries.indexOf(userCountry) > -1);
    var bShowGoldShield = (snaxXebecCountries.indexOf(userCountry) > -1);
    var bShowOlivier = (snaxXebecCountries.indexOf(userCountry) > -1);

    this.setState(prevState => ({
      homeIconDisplay: {
        marketplaceIconDisplay: {
          ...prevState.homeIconDisplay.marketplaceIconDisplay,
          displayicon: bShowMarketplace,
        },
        goldshieldIconDisplay: {
          ...prevState.homeIconDisplay.goldshieldIconDisplay,
          displayicon: bShowGoldShield,
        },
        olivierIconDisplay: {
          ...prevState.homeIconDisplay.olivierIconDisplay,
          displayicon: bShowOlivier,
        },
        zinzinoIconDisplay: {
          ...prevState.homeIconDisplay.zinzinoIconDisplay,
          displayicon: bShowZinzino,
        },
        muscadineIconDisplay: {
          ...prevState.homeIconDisplay.muscadineIconDisplay,
          displayicon: bShowMuscadine,
        },
        xebecIconDisplay: {
          ...prevState.homeIconDisplay.xebecIconDisplay,
          displayicon: bShowXebec,
        },
        satoshiIconDisplay: {
          ...prevState.homeIconDisplay.satoshiIconDisplay,
          displayicon: bShowSatoshi,
        },
        honeyBarsIconDisplay: {
          ...prevState.homeIconDisplay.honeyBarsIconDisplay,
          displayicon: bShowHoneyBars,
        },
        tgfIconDisplay: {
          ...prevState.homeIconDisplay.tgfIconDisplay,
          displayicon: bShowSnax,
        }
      }
    }));
  };

  setHomeItems = () => {
    const memberOnlyItems = this.props.baselineLoad[1].parentCategoryIds.filter((categoryItem) => {
      return categoryItem.memberOnly;
    });
    const publicOnlyItems = this.props.baselineLoad[1].parentCategoryIds.filter((categoryItem) => {
      return !categoryItem.memberOnly;
    });
    publicOnlyItems.push({
      parentCategoryID: null, parentCategoryName: "merchandise", memberOnly: false, parentCategoryImage: null, childCategories: []
    });
    this.setState({
      allParentCategoryIds: this.props.baselineLoad[1].parentCategoryIds,
      memberCategories: memberOnlyItems,
      publicCategories: publicOnlyItems
    });
  };

  onFeaturedProductsHandler = () => {
    this.props.history.push(`/category/${793}`);
  };

  showHeader = () => {
    const { hideNav } = this.props;
    if (!!hideNav)
      return false;
    else
      return true;
  };

  render() {
    const { t } = this.props;
    const { showFooter } = this.state;

    let homeIcons = [];
    for (const key of Object.keys(this.state.homeIconDisplay)) {
      if (this.state.homeIconDisplay[key].displayicon === true) {
        homeIcons.push(
          <div key={this.state.homeIconDisplay[key].imageURL} className="owl-stage">
            <Link to={this.state.homeIconDisplay[key].link} key={this.state.homeIconDisplay[key].link}>
              <img
                id="pic"
                src={this.state.homeIconDisplay[key].imageURL}
                alt=""
              />
            </Link>
          </div>);
      }
    }
    let displayLoop = false;
    let center = false;
    if (homeIcons.length > 4) {
      displayLoop = true;
      center = true;
    }

    const options = {
      loop: displayLoop,
      nav: false,
      center: center,
      autoplay: false,
      dots: false,
      mouseDrag: true,
      touchDrag: true,
      responsive: {
        0: {
          items: 3,
        },
        350: {
          items: 4,
        },
        1000: {
          items: 6,
        },
      }
    };

    return (
      <>
        {
          !!this.showHeader()
            ? (<Header isGuest={this.props.isGuest} />)
            : (<div className="quick-buy-spacer"></div>)
        }
        <div className="main-content">
          <Container className="main-content__wrapper">
            {
              this.props.promotionalProducts ? (
                <div className="main-content__promotional-products animated fadeIn delay-.1s">
                  <PromotionalProductsCarousel
                    parentCategoryID={this.props.match.params.categoryId}
                    products={this.props.promotionalProducts}
                  />
                </div>
              ) : (
                ''
              )
            }
            <div className="main-content__featured-products">
              {
                this.props.featuredProducts ? (
                  <div>
                    <div className="main-content__featured-products__header">
                      <h1 className="main-content__title">{t('home.featuredproducts')}</h1>
                      <p onClick={() => this.onFeaturedProductsHandler()}>{t('subcategories.seeall')}</p>
                    </div>
                    {
                      this.props.featuredProducts.length < 4 ? (
                        <Row>
                          <ProductList
                            category={'featured-products'}
                            products={this.props.featuredProducts}
                          />
                        </Row>
                      ) : (
                        <FeaturedProductsCarousel
                          products={this.props.featuredProducts}
                        />
                      )
                    }
                  </div>
                ) : (
                  ''
                )
              }

            </div>
            <div className="main-content__sub-categories">
              {
                homeIcons.length > 0 ?
                  (
                    <OwlCarousel options={options}>
                      {homeIcons}
                    </OwlCarousel>
                  ) : ('')
              }
            </div>
            <div className="main-content__categories">
              <MainCategoryListContainer
                homeContainerTitle={'Categories'}
                categoryItems={this.state.publicCategories}
              />
            </div>
            {
              this.props.isGuest || !this.props.userDetails || this.props.userDetails.length <= 0 || this.props.userDetails[0].IsMember == 0 ? (
                ''
              ) : (
                <div className="main-content__member">
                  <hr />
                  <MainCategoryListContainer
                    homeContainerTitle={'Membery Categories Only'}
                    categoryItems={this.state.memberCategories}
                  />
                </div>
              )
            }
          </Container>
        </div>
        {showFooter && <Footer />}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    promotionalProducts: state.productCategoryReducer.promotionalProducts,
    featuredProducts: state.productCategoryReducer.featuredProducts,
    marketAndCustomerLevelID: state.userReducer.marketAndCustomerIdParam,
    baselineLoad: state.productCategoryReducer.baselineLoad,
    langApi: state.userReducer.langApiParam,
    userDetails: state.userReducer.userDetails
  };
};

export default connect(mapStateToProps, {
  getFeaturedProducts,
  getPromotionalProducts,
  getUserDetails
})(withTranslation()(HomeMainContent));