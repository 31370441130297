import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class MediaListItem extends Component {
  static audioTrack;

  constructor(props) {
    super(props);
    this.playIndicatorRef = React.createRef();
    this.state = {
      audioIsPlaying: false,
    }
  }

  componentWillUnmount = async () => {
    this.props.pauseTrack(this.props.idx);
  }

  removeClassList = (e) => {
    const els = document.querySelectorAll('.modal-audio-list__audio-control')

    els.forEach( (item) => {
      item.children[4].classList.add('hide-item')

      if (item.children[0].classList.contains('fa-pause-circle')) {
        item.children[0].classList.remove('fa-pause-circle')
        item.children[0].classList.add('fa-play-circle')
      }
    })

    this.playIndicatorRef.current.classList.remove('hide-item')
    e.target.classList.remove('fa-play-circle')
    e.target.classList.add('fa-pause-circle')
  }
  
  playTrack = (track, idx, e) => {
    if (this.state.audioIsPlaying && this.props.idx === this.props.currentIdx) {
      this.props.pauseTrack(idx)
      e.target.classList.remove('fa-pause-circle')
      e.target.classList.add('fa-play-circle')
      this.playIndicatorRef.current.classList.add('hide-item')
      this.setState({ audioIsPlaying: false })
    } else {
      this.props.playTrackAudio(track, idx)
      this.setState({ audioIsPlaying: true })
    }
  }

  render() {
    const { audioItem, t } = this.props;

    return (
      <div className="modal-audio-list__container-item">
        <div className="modal-audio-list__audio-control"
        >
          {
            audioItem.mediaURL.length < 1 ? (
              <>
                <p className="modal-audio-list__subtype">{t(`audiomodal.audio`)}</p>
                <img className="audio-img" src={audioItem.imageURL} />
                <div></div>
              </>
            ) : (
                <>
                  <i
                    onClick={ (e) => {
                      this.removeClassList(e)
                      this.playTrack(audioItem.mediaURL, this.props.idx, e)
                    }}
                    className="modal-audio-list__play-icon fal fa-play-circle">
                  </i>
                  <img className="audio-img" src={audioItem.imageURL} />
                  <div></div>
                </>
              )
          }

          <h1 className="modal-audio-list__audio-title">{audioItem.productName}</h1>
          <img
            alt="play indicator"
            ref={this.playIndicatorRef}
            className="modal-audio-list__audio-indicator hide-item"
            src="https://m.media-amazon.com/images/G/01/digital/music/player/web/dragonfly/eqSmBlueLoop.gif">
          </img>
        </div>
        <div>
        </div>
      </div>

    )
  }
}

export default withTranslation()(MediaListItem);
