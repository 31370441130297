import React, { Component } from 'react'
import { connect } from "react-redux";
import Footer from '../components/Footer'

import SignInModal from '../components/Header/Navbar/SignInModal'
import { verifyToken, 
  setLangApiParam,
  selectedCountry,
  setCustomerAndMarketIDParam,
  setCurrency,
  getUserDetails,
  getBaselineLoad,
  getFeaturedProducts,
  getPromotionalProducts
} from '../store/actions';
import { getCookie, deleteCookie } from '../utils/cookieUtil';

class Login extends Component {

  componentDidMount = async () => {

    const isGuestCookie = getCookie('is_guest');
    if (isGuestCookie) {
      return;
    }

    const access_token = getCookie('access_token');
    if (access_token) {
      this.props.verifyToken(access_token, ((response) => {
        this.props.getUserDetails(access_token, ((response) => {
          this.setGlobalVariables(response);
          this.props.history.push('/')
        }).bind(this));
      }).bind(this), ((error) => {
        deleteCookie("access_token");
        deleteCookie("cart_guid");
        deleteCookie("selected_lang");
        this.props.history.push('/login');
      }).bind(this));
    } else {
      deleteCookie("access_token");
      deleteCookie("cart_guid");
      deleteCookie("selected_lang");
      this.props.history.push('/login');
    }
  }

  componentDidUpdate = async (prevProps) => {

    const isGuestCookie = getCookie('is_guest');
    if (isGuestCookie) {
      return;
    }

    if (prevProps.userToken !== this.props.userToken) {
      this.props.verifyToken(this.props.userToken.access_token, ((response) => {
        const access_token = getCookie('access_token')
        this.props.getUserDetails(access_token, ((response) => {
          this.setGlobalVariables(response);
          this.props.history.push('/')
        }).bind(this));
      }).bind(this), ((error) => {
        deleteCookie("access_token");
        deleteCookie("cart_guid");
        deleteCookie("selected_lang");
        this.props.history.push('/login');
      }).bind(this));
    }
  }

  setGlobalVariables = (userData) => {
    const access_token = getCookie('access_token');
    this.props.getBaselineLoad(access_token, '', ((response) => {
      let countryParams;
      response[0].marketIds.forEach((country, i) => {
        // have two values for country (user)
        if (country.countryValue === userData[0].Country) {
          this.props.selectedCountry(country)
          countryParams = country
        }
      })
      this.props.setCustomerAndMarketIDParam({
        customerLevelID: userData[0].CustomerLevelID,
        marketID: userData[0].MarketID
      });
      this.props.setLangApiParam(userData[0].Language)
      this.props.setCurrency(countryParams.currency)
      this.props.getFeaturedProducts(access_token, userData[0].Language)
      this.props.getPromotionalProducts(access_token, userData[0].Language)
    }).bind(this));
  }
  
  render() {
    return (
      <> 
        <SignInModal />
        <Footer />
      </>
    )
  }
}

const mapStateToUser = (state) => {
  return {
    userToken: state.userReducer.loginUser
  }
}

export default connect(mapStateToUser, {
  verifyToken, 
  setLangApiParam,
  selectedCountry,
  setCustomerAndMarketIDParam,
  setCurrency,
  getUserDetails,
  getBaselineLoad,
  getFeaturedProducts,
  getPromotionalProducts
})(Login);