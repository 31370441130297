import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Row from 'react-bootstrap/Row';

import MainCategoryListContainerItem from './MainCategoryListContainerItem';

class HomeListContainer extends Component {

  renderHomeListItems = () => {
    const { categoryItems } = this.props;
    if (categoryItems) {
      return categoryItems.map((category, i) => {

        return <MainCategoryListContainerItem
          key={i}
          childCategories={category.childCategories}
          category={category}
        />
      })
    }
  }

  render() {
    return (
      <Row className="main-content__container">
        {this.renderHomeListItems()}
      </Row>
    )
  }
}

export default withRouter(HomeListContainer);
